import {AnnouncementDisplay, AnnouncementRequest, EAnnouncementNotification} from './../data/announcement.data';
import {MetaInformation} from './../data/metaInformation.data';
import {
  CreateCustomFeedbackEntryRequest,
  CreateCustomFeedbackResponseRequest,
  CustomFeedbackEntryResponse
} from './../data/customFeedback.data';
import {IHttpService, ILogService} from 'angular';
import {
  AvailabilitySummaryResponse,
  DeleteFunctionGroupRequest,
  EAvailabilityState,
  FailedRules
} from '../data/availability.data';
import {CalendarAccess, CalendarSimple, ECalendarType} from "../data/calendar.data";
import {CustomFeedbackResponseResponse} from '../data/customFeedback.data';
import {EEventState, EEventType, Event, EventAccess, EventFeedbackResponse} from "../data/event.data";
import {
  APagerTokenForPersonResponse,
  ChangeFunctionGroupForPerson,
  DataBasePersonForTracking,
  DatabasePersonProvisioningRepresentation,
  DeletePersonRequest,
  OrganisationPaginatedResponse,
  PaginatedDatabasePersonForAlarmGroups,
  PaginatedDatabasePersonForTracking,
  PaginatedDatabasePersonProvisioningRepresentation,
  PaginatedPersonSearchQueryResult,
  Person,
  PersonNewFieldStructure,
  PersonQuickEditResponse,
  PersonsAddressbookFullResponse,
  PersonsAddressbookResponse,
  PersonSearchQueryResult,
  ProvisioningVersionResponse,
  SharePersonRequest,
  UserSearchResponse
} from '../data/person.data';
import {CurrentPosition} from '../data/tracking.data';
import {EWidgetState, WidgetData} from '../data/widget.data';
import {API_ENDPOINT, GAE_ENDPOINT} from './../constants';
import {
  AddVehicleRequest,
  EVehicleAvailability,
  EVehicleStatus,
  PutVehicleAccessRequest,
  StatusGrid,
  TacticalSignCategory,
  UpdateVehicleAvailabilityRequest,
  UserLocationResponse,
  Vehicle,
  VehicleForReport,
  VehicleForReportSlice,
  VehicleIcons,
  VehicleLocationHistoryResponse,
  VehicleLocationResponse,
  VehicleSirenResponse,
  VehiclesPaginatedResponse,
  VehiclesSimplePaginatedResponse
} from '../data/vehicles.data';
import {
  AccountResponse,
  AdminLoginResetRequest,
  AdminLoginResetResponse,
  ChangePasswordMode,
  FidoRegistration,
  LoginRequest,
  LoginTokenState,
  RevisionState,
  SecondFactorValidation,
  Server,
  UserContext,
  UserContextSearchResult,
  UserTokenResponse
} from '../data/account.data';
import {
  AddressSettings,
  AdjustLicenceType,
  AdminAddressbookResponse,
  AdminAlarmObjectsResponse,
  AdminStreetsResponse,
  AlarmSplitterConfig,
  ApproveKbaRequest,
  BackendFallbackInfo,
  BlockedAlarmsPaginated,
  ComingHomeSettings,
  ConfigStatusResponse,
  ConnectionResponse,
  CreateOrganisationRequest,
  CustomerNotificationResponse,
  DeactivatedPluginListEntry,
  DuplicateGlobalSettings,
  ERelaisState,
  FeedbackGroupsUnits,
  FeedbackUnitGroupSimple,
  GeneralResponse,
  HttpMetrics,
  KbaRequest,
  KbaResponse,
  KeywordSettings,
  LicenceDistribution,
  MailAccountSettings,
  MasterSlaveSettings,
  MiscSettings,
  MqttStatus,
  OnlineServiceMapping,
  OrganisationLicenceSetAndAvailableData,
  OutageSettings,
  OutputPluginSettings,
  ReplacementSettings,
  RestProtocolSettings,
  SimpleUnit,
  SmsEagleQueueLength,
  SmsEagleSettings,
  SmsEagleSignal, SsoBlockedResponse,
  SsoOnResponse,
  SSOSettings, StatusSettings,
  UpdateOnlineServiceMapping,
  UpdateOrganisationLicenseRequest,
  UpdateUserRequest,
  User,
  UserAdminContext,
  VehicleSwapHistory,
  WebserverSettings
} from '../data/admin.data';
import {AMobileAddressbookResponse} from '../data/amobile.data';
import {
  AlarmMonitor,
  AlarmMonitorAddressbookResponse,
  AlarmMonitorDefaultConfig,
  AlarmMonitorType
} from '../data/am4.data';
import {AddressBookOverViewData} from '../data/overview.data';
import {
  AddUnitRequest,
  AdminAddUnitRequest,
  DeactivatedUnits,
  EditUnit,
  PipelineProtocolEntry,
  Splitter,
  Unit,
  UnitFilterEntry,
  UnitFilterResponse
} from '../data/unit.data';
import {
  AlarmGroup,
  AlarmGroupsForPersonRequest,
  AlarmGroupSimple,
  SingleAlarmGroupSaveRequest
} from '../data/alarmgroup.data';
import {
  AlarmObjectChange,
  AlarmObjectSearchResult,
  AlarmObjectTableRowEntry,
  AssignAlarmObject,
  DocumentTag,
  ObjectDocument,
  ObjectDocumentsPageResponse,
  ObjectDocumentTagsResponse,
  SimpleAlarmObject
} from '../data/objects.data';
import {
  AlarmData,
  AlarmDataPage,
  AlarmFakerRequest,
  AlarmRequestSimple,
  EVehicleSortOrder,
  PaginatedKeywordResponse,
  VehicleAssignedAlarm,
  VehicleAssignedAlarmCombined
} from '../data/alarm.data';
import {RevisionListResponse} from '../data/revisionListResponse.data';
import {Announcement, AnnouncementResponses} from '../data/announcement.data';
import {
  AAO,
  AAOEntryResponse,
  AAOEntryResponseElement,
  AAOResponsePaginated,
  AAOVehicleAssignment
} from '../data/aao.data';
import {AgeVerificationSetting, PrivacyMappingResponse, PrivacySettingsResponse} from '../data/admin.settings';
import {
  AssignRoleRequest,
  CreateRoleRequest,
  Role,
  RoleSimpleResponse,
  RoleSimpleSelection,
  RoleUpdateRequest
} from '../data/role.data';
import {LogObjectEntryTransferable, LogObjectTransferable} from '../data/tracelog.data';
import {CustomMapLayer} from '../data/customMapLayer.data';
import {
  Gate,
  GateUpdateRequest,
  ListParkingPosition,
  ParkingPosition,
  ParkingPositionUpdateRequest,
  RelaisNameResponse
} from '../data/building.data';
import {AlarmTemplatePaginatedResponse} from '../data/template.data';
import {
  CreateInputPluginInstance,
  InputPlugin,
  InputPluginDetails,
  SimpleInputPluginInstance
} from '../data/input.data';
import * as webauthnJson from "@github/webauthn-json";
import {ProvisioningGeoFenceSelection, ProvisioningResponse, TermsOfUse} from "../data/provisioning.data";
import {ProvisioningGeoFenceEdit} from "../data/geofence.data";
import {IOBattery, IOBatteryMeasurement} from '../data/iobattery.data';
import {
  BuildingControllerResponse,
  BuildingControllerTypesResponse,
  CreateBuildingControllerRequest,
  SimpleBuildingControllerResponse
} from '../data/controller.data';
import {GamificationBadgeResult, LeaderboardResultEntry} from '../data/leaderboard.data';
import {
  LeaderboardSortBy,
  LeaderboardSortOrder
} from '../components/views/leaderboard.view.component/leaderboard.view.component';
import {ExaminationObject, ExaminationObjectEditRequest} from '../data/examination.data';
import {InputTrackingToggle, VehicleGeoTrackingStateResponse} from "../data/geotracking.data";
import {GeoJsonObject} from 'geojson';
import {
  Emergency,
  EmergencyImageInfo,
  EmergencyReport,
  EmergencyResponse,
  EMissionReportState,
  LagekarteImageData,
  MissionReport,
  MissionReportSlice,
  UpdateMissionStateRequest
} from '../data/emergency.data';
import {BooleanResponse, Page, Slice} from '../data/misc.data';
import {UserSettingsAsAdminRequest} from '../data/user.data';
import {
  MoveToQueueRequest,
  Queue,
  QueueEntryResponse,
  QueueEntryVehicle,
  QueueResponse,
  QueueTableResponse,
  UpdateQueueActiveStateRequest,
  UpdateQueuePriorityRequest,
  UpdateQueueStateRequest
} from '../data/queue.data';
import {DWDWeatherInformation, WeatherAndPegelWarnings} from '../data/weather.data';
import {WmsLayerData, WmsLayerDataUpdateRequest, WmsServerData} from '../data/custom.map.server.data';
import {IOprint, IOprintJobInfoResponsePage, IOPrintsInfoResponse, IOprintUpdateRequest} from "../data/ioprint.data";
import {DetourMatch, Roadblock} from '../data/roadblock.data';
import {
  ESinkType,
  SinkCurrentStateResponse,
  SinkResponse,
  SinksResponsePaginated,
  SinkStateRequest
} from "../data/sinks.data";
import {
  CreateDeviceRequest,
  DeviceResponse,
  DeviceStateChangeResponse,
  EDeviceState,
  EDeviceType,
  VehicleDeviceResponse
} from '../data/device.data';
import {VehicleSwapOptions} from '../components/modals/wache/swap.vehicles.modal/swap.modal';
import {ChatroomAdminUserResponse, ChatroomResponse, SimpleChatroomResponse} from '../data/chat.data';
import {WorkloadResponse} from '../data/workload.data';
import {IvenaMapping} from '../data/ivena.data';
import {UploadType} from '../components/directives/admin/admin.settings/admin.logo.settings/admin.logo.component';
import {StatusDefinition} from '../data/status.data';

'use strict';
import angular = require('angular');
import {MapSearchResult, MapSearchResults} from '../data/wache.data';
import {DiaryEntry, DiaryPayload, ELocationMapTaskStatus, LocationMapTask, LocationMapTaskPayload} from '../data/locationmap.data';

/* @ngInject */
export default class RestService {


  private $log: ILogService;
  private $http: IHttpService;
  private $translate;
  private errorService;
  private helperService;
  private localStorageService;
  private FileUploader;

  private baseUrl: string;
  private baseGae: string;
  private token: string;

  constructor($log: ILogService, $http: IHttpService, $translate, localStorageService, FileUploader, errorService, helperService) {
    $log.debug('Init RestService');
    this.$log = $log;
    this.$http = $http;
    this.$translate = $translate;
    this.errorService = errorService;
    this.helperService = helperService;
    this.localStorageService = localStorageService;
    this.FileUploader = FileUploader;

    this.baseGae = GAE_ENDPOINT;
    this.baseUrl = API_ENDPOINT;


    this.$log.debug('Endpoint: ' + this.baseUrl);
  }

  handleErrorResponse(response, error) {
    this.$log.error(response);

    if (response.data !== null && response.data.message) {
      this.errorService.notifyWithText(response.data.message);
      error(response.data.message, response);
      return;
    }

    if (response.status === 404) {
      error(this.errorService.ERROR_IDS.NOT_FOUND, response);
      this.errorService.notify(this.errorService.ERROR_IDS.NOT_FOUND);
    } else if (response.status === 403) {
      //Reload to get back to home
      error(this.errorService.ERROR_IDS.FORBIDDEN, response);
      this.errorService.notify(this.errorService.ERROR_IDS.FORBIDDEN);
    } else if (response.status === 400) {
      error(this.errorService.ERROR_IDS.INVALID_ARGUMENTS, response);
      this.errorService.notify(this.errorService.ERROR_IDS.INVALID_ARGUMENTS);
    } else if (response.status === 406) {
      error(this.errorService.ERROR_IDS.NOT_ACCEPTABLE, response);
      this.errorService.notify(this.errorService.ERROR_IDS.NOT_ACCEPTABLE);
    } else if (response.status === 401) {
      error(this.errorService.ERROR_IDS.NOT_AUTHORIZED, response);
      this.errorService.notify(this.errorService.ERROR_IDS.NOT_AUTHORIZED);
    } else if (response.status === 412) {
      error(this.errorService.ERROR_IDS.NOT_VALID, response);
      this.errorService.notify(this.errorService.ERROR_IDS.NOT_VALID);
    } else if (response.status === 423) {
      error(this.errorService.ERROR_IDS.RESSOURCE_LOCKED, response);
      this.errorService.notify(this.errorService.ERROR_IDS.RESSOURCE_LOCKED);
    } else {
      error(this.errorService.ERROR_IDS.UNKNOWN_ERROR, response);
      this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
    }
  }

  handleSuccessResponse(response, success) {
    success(response);
  }

  //#####
  //ACCOUNT
  //#####

  /**
   Get base url
   */
  getBaseUrl() {
    return this.baseUrl;
  };

  /**
   * Get the path for a static image
   * @param {*} name
   */
  getStaticImageUrl(name) {
    return this.baseUrl + '/files/images?name=' + encodeURI(name);
  }

  /**
   Load all available vehicles
   */
  loadVehicles(success, error) {
    this.$log.debug('Loading vehicles...');

    this.$http.get(this.baseUrl + '/vehicles').then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error getting Loading vehicles');
      this.handleErrorResponse(response, error);
    });
  }

  loadVehiclesPaginated(currentPage: number, limit: number, filter: string, ordering: string, success, error) {
    this.$http.get(this.baseUrl + '/vehicles/paginated?page=' + currentPage + '&limit=' + limit + '&filter=' + encodeURIComponent(filter) + '&vehicleRequestType=VEHICLES' + '&ordering=' + ordering).then((response) => {
      this.$log.debug('Successfully loaded vehicles...');
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error getting vehicles');
      this.handleErrorResponse(response, error);
    });
  }

  loadVehiclesByTypePaginated(currentPage: number, limit: number, filter: string, vehicleRequestType: string, ordering: string, success, error) {
    this.$http.get(this.baseUrl + '/vehicles/paginated?page=' + currentPage + '&limit=' + limit + '&filter=' + encodeURIComponent(filter) + '&vehicleRequestType=' + vehicleRequestType + '&ordering=' + ordering).then((response) => {
      this.$log.debug('Successfully loaded vehicles...');
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error getting vehicles');
      this.handleErrorResponse(response, error);
    });
  }

  loadVehiclesPaginatedAsPromiseWithOrganisationAccess(currentPage: number, limit: number, filter: string, ordering: string, user: User): Promise<VehiclesPaginatedResponse> {
    return new Promise<VehiclesPaginatedResponse>((resolve, reject) => {
      let url = this.baseUrl + '/vehicles/' + user.id + '/access/users?page=' + currentPage + '&limit=' + limit + '&filter=' + encodeURIComponent(filter) + '&ordering=' + ordering;

      this.$http.get(url).then((response) => {
        resolve(response.data as VehiclesPaginatedResponse);
      }, (error) => {
        this.$log.error('Error getting vehicles');
        reject(error);
      });
    });
  }

  loadVehiclesPaginatedAsPromise(currentPage: number, limit: number, filter: string, ordering: string, filteredUserIds?: string[]): Promise<VehiclesPaginatedResponse> {
    return new Promise<VehiclesPaginatedResponse>((resolve, reject) => {
      let url = this.baseUrl + '/vehicles/paginated?page=' + currentPage + '&limit=' + limit + '&filter=' + encodeURIComponent(filter) + '&ordering=' + ordering;
      if (filteredUserIds) {
        url = url + '&filteredUserIds=' + filteredUserIds.join(';');
      }
      this.$http.get(url).then((response) => {
        resolve(response.data as VehiclesPaginatedResponse);
      }, (error) => {
        this.$log.error('Error getting vehicles');
        reject(error);
      });
    });
  }


  loadVehiclesPaginatedSimple(currentPage: number, limit: number, filter: string, ordering: string, filteredUserIds?: string[]): Promise<VehiclesSimplePaginatedResponse> {
    return new Promise<VehiclesSimplePaginatedResponse>((resolve, reject) => {
      let url = this.baseUrl + '/vehicles/paginated/simple?page=' + currentPage + '&limit=' + limit + '&filter=' + encodeURIComponent(filter) + '&ordering=' + ordering;
      if (filteredUserIds) {
        url = url + '&filteredUserIds=' + filteredUserIds.join(';');
      }
      this.$http.get(url).then((response) => {
        resolve(response.data as VehiclesSimplePaginatedResponse);
      }, (error) => {
        reject(error);
      });
    });
  }

  loadVehiclesSlicesAsPromise(currentPage: number, limit: number, filter: string, userId: string): Promise<VehicleForReportSlice> {
    return new Promise<VehicleForReportSlice>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${userId}/report?page=${currentPage}&limit=${limit}&filter=${encodeURIComponent(filter)}`).then((response) => {
        resolve(response.data as VehicleForReportSlice);
      }, error => {
        reject(error.data.message);
      });

    });

  }

  loadVehicleForReport(userId: string, vehicleId: string): Promise<VehicleForReport> {
    return new Promise((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${userId}/report/${vehicleId}`).then((response) => {
        resolve(response.data as VehicleForReport);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.message);
      })
    })

  }

  /**
   Check if wasserkarte.info token is valid
   */
  isWasserkarteTokenValid(token, success, error) {
    this.$log.debug('Check if wasserkarte.info token is valid...');

    var url = this.baseUrl + '/objects/wasserkarte/test?token='+token
    this.$http.get(url).then((response) => {
      if (response.status === 200) {
        success();
      } else {
        error();
      }
    }, (response) => {
      this.$log.error('Error receiving token');
      error();
    });
  };

  /**
   Get vehicles with status change
   */
  getVehiclesWithStatusChange(start, end, success, error) {
    this.$http.get(this.baseUrl + '/vehicles?start=' + start + '&end=' + end).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting vehicles');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   Get vehicles with status change for alarm closing
   */
  getVehiclesForAlarmClosingWithStatusChange(start, end, success, error) {
    this.$http.get(this.baseUrl + '/vehicles/alarmClosing?start=' + start + '&end=' + end).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error getting vehicles');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   * add a new vehicle
   * @param data name and code of new vehicle
   * @param success
   * @param error
   */
  addVehicle(name, code): Promise<Vehicle> {
    var data = {
      name: name,
      code: code
    } as AddVehicleRequest

    return new Promise<Vehicle>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/vehicles/create', data)
        .then((response) => {
          resolve(response.data as Vehicle);
        }, (error) => {
          this.$log.error('Error creating vehicle');
          this.errorService.notifyWithText(error.data.message);
          reject(error);
        });
    });
  };


  /**
   * Log off all active users by generating a new secure signing key
   */
  logOffAllUsers(success, error) {
    this.$http.get(this.baseUrl + '/admin/regenerate').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting vehicles');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Compact the databases storage
   */
  compactStorage(success, error) {
    this.$http.get(this.baseUrl + '/admin/compact').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error compacting storage');
      this.handleErrorResponse(response, error);
    });
  };

  cleanLogFiles(success, error) {
    this.$http.get(this.baseUrl + '/admin/clean/logs').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error compacting storage');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load all provisioning configurations
   */
  loadProvisioning(success, error) {
    this.$log.debug('Loading provisionings...');

    this.$http.get(this.baseUrl + '/apager/provisionings').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting provisionings');
      this.handleErrorResponse(response, error);
    });
  };

  loadAllGeofenceProvisioningsForUser(): Promise<ProvisioningGeoFenceSelection[]> {
    return new Promise<ProvisioningGeoFenceSelection[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/apager/provisionings/geofences').then((response) => {
        resolve(response.data as ProvisioningGeoFenceSelection[]);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  loadAllGeofenceForUser(): Promise<ProvisioningGeoFenceSelection[]> {
    return new Promise<ProvisioningGeoFenceSelection[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/geofences').then((response) => {
        resolve(response.data as ProvisioningGeoFenceSelection[]);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  loadAllGeofencePositionsForUser(): Promise<ProvisioningGeoFenceEdit[]> {
    return new Promise<ProvisioningGeoFenceEdit[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/geofences/positions').then((response) => {
        resolve(response.data as ProvisioningGeoFenceEdit[]);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  loadSingleGeofence(geofenceId: string): Promise<ProvisioningGeoFenceEdit> {
    return new Promise<ProvisioningGeoFenceEdit>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/geofences/' + geofenceId).then((response) => {
        resolve(response.data as ProvisioningGeoFenceEdit);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      })
    })
  }

  deleteSingeleGeofence(geofenceId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/geofences/' + geofenceId).then(() => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      })
    })
  }

  updateGeofenceProvisioning(provisioningGeofence: ProvisioningGeoFenceEdit): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/geofences/update', provisioningGeofence).then(() => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      })
    })
  }

  createGeofenceProvisioning(provisioningGeofence: ProvisioningGeoFenceEdit): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/geofences/create', provisioningGeofence).then(() => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      })
    })
  }

  /**
   Create a new provisioning configuration
   */
  createProvisioning(success, error) {
    this.$log.debug('Create a new provisioning...');

    this.$http.post(this.baseUrl + '/apager/provisionings', undefined).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error creating provisionings');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create a new provisioning configuration
   */
  duplicateProvisioning(id: string, success, error) {
    this.$log.debug('Duplicate existing provisioning: ' + id);
    this.$http.post(this.baseUrl + '/apager/provisionings/' + encodeURIComponent(id) + '/duplicate', undefined).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error duplicating provisioning');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete existing provisioning configuration
   */
  deleteProvisioning(prov, success, error) {
    this.$log.debug('Delete an existing provisioning...');

    this.$http.delete(this.baseUrl + '/apager/provisionings/' + prov.id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting provisionings');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get a single provisioning profile
   */
  getSingleProvisioning(id, success, error) {
    this.$log.debug('Loading single provisioning profile: ' + id);

    this.$http.get(this.baseUrl + '/apager/provisionings/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting single provisioning profile');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update a existing provisioning configuration
   */
  saveProvisioning(prov, success, error) {
    this.$log.debug('Save an existing provisioning...');

    this.$http.put(this.baseUrl + '/apager/provisionings/' + prov.id, prov).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving provisioning');
      this.handleErrorResponse(response, error);
    });
  };

  getTermsOfUse() {
    return new Promise<TermsOfUse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/apager/provisionings/terms`).then((response) => {
        resolve(response.data as TermsOfUse);
      }, (err) => {
        reject(err);
      });
    });
  };

  /**
   Provision an existing person
   */
  provisionAsPromise(provisioningId: string, id: string, isGroupId: boolean, useAutomaticMode: boolean): Promise<ProvisioningResponse> {
    return new Promise<ProvisioningResponse>((resolve, reject) => {
      let url = this.baseUrl + '/apager/provisioningToDevice?' + (isGroupId ? 'groupId=' : 'personId=') + id + '&provId=' + provisioningId;
      url = `${url}&useAutoMode=${useAutomaticMode}`;

      this.$http.get(url).then((response) => {
        resolve(response.data as ProvisioningResponse);
      }, (response) => {
        this.$log.error('Error provisioning person');
        reject(response.data);
      });
    })

  };


  /**
   Provision an existing person
   */
  provision(provisioningId: string, id: string, isGroupId: boolean, useAutomaticMode: boolean, success, error) {
    let url = this.baseUrl + '/apager/provisioningToDevice?' + (isGroupId ? 'groupId=' : 'personId=') + id + '&provId=' + provisioningId;
    url = `${url}&useAutoMode=${useAutomaticMode}`;

    this.$http.get(url).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error provisioning person');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Provision an existing person
   */
  provisionSinglePerson(provisioningId: string, id: string, useAutomaticMode: boolean): Promise<ProvisioningResponse> {
    let url = `${this.baseUrl}/apager/provisioningToSingleDevice/${id}?provId=${provisioningId}`;
    url = `${url}&useAutoMode=${useAutomaticMode}`;
    return new Promise<any>((resolve, reject) => {
      this.$http.get(url).then((response) => {
        resolve(response.data as ProvisioningResponse);

      }, (response) => {
        this.$log.error('Error provisioning person');
        reject(response);
        this.errorService.notifyWithText(response.data.message);
      });
    });

  };


  /**
   Load aMobile Vehicle mapping
   */
  loadAmobileMapping(success, error) {
    this.$log.debug('Loading aMobile mapping...');

    this.$http.get(this.baseUrl + '/vehicles/amobile').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting aMobile mapping');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load all available parking positions
   */
  loadParkingPositions(success, error) {
    this.$log.debug('Loading parking positions...');

    this.$http.get(this.baseUrl + '/building/parkingPositions').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting parking positions');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Set parking position for vehicle
   */
  setParkingPositionForVehicle(vehicle: Vehicle, parkingPosition: ParkingPosition): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      this.$log.debug('Set parking position for vehicle...');
      this.$http.put(`${this.baseUrl}/vehicles/${vehicle.id}/parking`, {
        id: parkingPosition.id
      }).then((response) => {
        resolve(response.data as Vehicle);
      }, (response) => {
        this.$log.error('Error getting parking position for vehicle');
        reject(response);
      });
    });

  };

  /**
   * Update vehicle status
   */
  updateVehicleStatus(vehicle, status, success, error) {
    this.$log.debug('Update status for vehicle...');
    this.$http.put(this.baseUrl + '/vehicles/' + vehicle.id + '/status', {
      status: status
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating status for vehicle');
      this.handleErrorResponse(response, error);
    });
  };

  getSirenStatus(success, error) {
    this.$http.get(this.baseUrl + '/vehicles/getSirenStatus').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading vehicle faults');
      this.handleErrorResponse(response, error);
    });
  }

  updateVehicleAvailability(vehicleId: string, availability: EVehicleAvailability): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/vehicles/${vehicleId}/availability`, {
        availability: availability
      } as UpdateVehicleAvailabilityRequest).then((response) => {
        resolve(response.data as Vehicle);
      }, (response) => {
        reject(response);
      });
    });
  }


  /**
   * Load vehicle status
   */
  getStatus(vehicle, page, limit, success, error) {
    if (angular.isDefined(vehicle)) {
      //Get single vehicle
      this.$http.get(this.baseUrl + '/vehicles/' + vehicle.id + '/status?page=' + page + '&limit=' + limit).then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error loading status');
        this.handleErrorResponse(response, error);
      });
    } else {
      //Get all vehicles
      this.$http.get(this.baseUrl + '/vehicles/status?page=' + page + '&limit=' + limit).then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error loading status');
        this.handleErrorResponse(response, error);
      });
    }
  }

  clearStatusHistoryForVehicle(vehicle: Vehicle): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/vehicles/' + vehicle.id + '/status').then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    })
  }


  /**
   * Load vehicle status
   */
  getVehicleFaults(vehicle, success, error) {
    if (angular.isDefined(vehicle)) {
      //Get single vehicle
      this.$http.get(this.baseUrl + '/vehicles/' + vehicle.id + '/faults').then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error loading vehicle faults');
        this.handleErrorResponse(response, error);
      });
    } else {
      this.$log.error('Can\'t get fault message for undefined vehicle')
    }
  }

  /**
   * Add a new fault for existing vehicle
   * @param {*} vehicle
   * @param {*} fault
   * @param {*} success
   * @param {*} error
   */
  addVehicleFault(vehicle, fault, success, error) {
    if (angular.isDefined(vehicle) && angular.isDefined(fault)) {
      //Add a new vehicle fault
      this.$http.post(this.baseUrl + '/vehicles/' + vehicle.id + '/faults', fault).then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error adding fault');
        this.handleErrorResponse(response, error);
      });
    } else {
      this.$log.error('Can\'t add fault message for undefined vehicle or fault')
    }
  }

  /**
   * Update existing vehicle fault
   * @param {*} vehicle
   * @param {*} fault
   * @param {*} success
   * @param {*} error
   */
  updateVehicleFault(vehicle, fault, success, error) {
    if (angular.isDefined(vehicle) && angular.isDefined(fault)) {
      //Update existing vehicle fault
      this.$http.put(this.baseUrl + '/vehicles/' + vehicle.id + '/faults/' + fault.id, fault).then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error updating fault for vehicle');
        this.handleErrorResponse(response, error);
      });
    } else {
      this.$log.error('Can\'t update fault message for undefined vehicle or fault')
    }
  }

  /**
   * Delete an existing vehicle fault
   * @param {} vehicle
   * @param {*} fault
   * @param {*} success
   * @param {*} error
   */
  deleteVehicleFault(vehicle, fault, success, error) {
    if (angular.isDefined(vehicle) && angular.isDefined(fault)) {
      //Delete existing vehicle fault
      this.$http.delete(this.baseUrl + '/vehicles/' + vehicle.id + '/faults/' + fault.id).then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error deleting fault for vehicle');
        this.handleErrorResponse(response, error);
      });
    } else {
      this.$log.error('Can\'t delete fault message for undefined vehicle or fault')
    }
  }

  closeAllVehicleFaults(vehicle: Vehicle): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$log.debug('Close all vehicle faults for vehicle...');
      this.$http.post(`${this.baseUrl}/vehicles/${vehicle.id}/faults/close`, {}).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

  /**
   * remove parking position for vehicle
   */
  removeParkingPositionForVehicle(vehicle: Vehicle): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      this.$log.debug('Remove parking position for vehicle...');
      this.$http.delete(`${this.baseUrl}/vehicles/${vehicle.id}/parking`).then((response) => {
        resolve(response.data as Vehicle);
      }, (response) => {
        reject(response);
      });
    });
  };

  /**
   * Get timeplan for vehicle
   */
  loadTimesForVehicle(vehicle, success, error) {
    this.$http.get(this.baseUrl + '/vehicles/' + vehicle.id + '/timeplan').then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error loading times for vehicle');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update timeplan for vehicle
   */
  updateTimesForVehicle(vehicle, timeplan, success, error) {
    this.$http.put(this.baseUrl + '/vehicles/' + vehicle.id + '/timeplan', timeplan).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating times for vehicle');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get timeplan for parking position
   */
  loadTimesForParkingPosition(parkingPosition, success, error) {
    this.$http.get(this.baseUrl + '/building/parkingPositions/' + parkingPosition.id + '/timeplan').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading times for parking positions');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update timeplan for parking position
   */
  updateTimesForParkingPosition(parkingPosition, timeplan, success, error) {
    this.$http.put(this.baseUrl + '/building/parkingPositions/' + parkingPosition.id + '/timeplan', timeplan).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating times for parking positions');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Load all relais
   */
  loadRelais(success, error) {
    this.$http.get(this.baseUrl + '/building/relais/').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading relais');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get all relais from server
   */
  getAllRelais(success, error) {
    this.$http.get(this.baseUrl + '/relais/').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting all relais from server');
      this.handleErrorResponse(response, error);
    });
  };

  getAllRelaisForAdmin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/relais/admin`).then((response) => {
        resolve(response.data);
      }, (error) => {
        reject(error);
      });
    });
  }

  getAllRelaisForUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/relais/`).then((response) => {
        resolve(response.data);
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   Load all available plugins
   */
  loadPlugins(success, error) {
    this.$log.debug('Loading plugins...');

    this.$http.get(this.baseUrl + '/units/plugins').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting plugins');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load single plugins
   */
  loadPlugin(name, success, error) {
    this.$log.debug('Loading plugin...');

    this.$http.get(this.baseUrl + '/units/plugins?name=' + encodeURIComponent(name)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting plugin');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get log files
   */
  getLogsInFolder(currentDir: string, dateFilter: number, success, error) {
    this.$http.get(`${this.baseUrl}/logs?currentDir=${encodeURIComponent(currentDir)}&dateFilter=${dateFilter}`).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting log files');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get input plugins
   */
  getAllInputs(): Promise<InputPlugin[]> {
    return new Promise<InputPlugin[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/input/active').then((response) => {
        resolve(response.data as InputPlugin[]);
      }, (error) => {
        reject(error);
      });
    });

  };

  getInput(id: string): Promise<InputPluginDetails> {
    return new Promise<InputPluginDetails>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/input/${encodeURIComponent(id)}`).then((result) => {
        resolve(result.data as InputPluginDetails);
      }, (error) => {
        reject(error.data.message);
        this.errorService.notifyWithText(error.data.message);
      })
    })
  }

  saveInputPluginDetailed(inputPluginDetailed: InputPluginDetails): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/input`, inputPluginDetailed).then((success) => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      })
    })
  }

  /**
   * Get input plugins
   */
  getAllAvailableInputs(): Promise<SimpleInputPluginInstance[]> {
    return new Promise<SimpleInputPluginInstance[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/input').then((response) => {
        resolve(response.data as SimpleInputPluginInstance[]);
      }, (error) => {
        reject(error);
      })
    })
  }


  /**
   Load a pipeline
   */
  loadPipeline(unitId: string, success, error) {
    this.$log.debug('Loading pipeline...');

    this.$http.get(`${this.baseUrl}/units/pipeline/${encodeURIComponent(unitId)}`).then((response) => {
      success(response.data);
    }, (response) => {
      this.$log.error('Error getting pipeline');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load a pipeline by its ID
   */
  loadPipelineById(unitId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/units/pipeline/${encodeURIComponent(unitId)}`).then((response) => {
        resolve(response.data);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  };

  /**
   Save a pipeline
   */
  savePipeline(unitId: string, pipeline, onlyValidate: boolean, protocolData: any, success, error) {
    this.$log.debug('Saving pipeline...');
    var data = {
      nodes: pipeline,
      protocolData
    };
    this.$http.put(`${this.baseUrl}/units/pipeline/${encodeURIComponent(unitId)}?validateOnly=${onlyValidate}`, data).then((response) => {
      success(response.data);
    }, (response) => {
      this.$log.error('Error saving pipeline');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Save unit settings
   */
  saveUnitSettings(unitId: string, settings, success, error) {
    this.$log.debug('Saving unit settings...');
    this.$http.put(`${this.baseUrl}/units/settings/${encodeURIComponent(unitId)}`, settings).then((response) => {
      success(response.data);
    }, (response) => {
      this.$log.error('Error saving unit settings');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load a single unit
   */
  loadUnit(unitId: string, success, error) {
    this.$log.debug('Loading unit...');

    this.$http.get(`${this.baseUrl}/units/unit/${encodeURIComponent(unitId)}`).then((response) => {
      success(response.data);
    }, (response) => {
      this.$log.error('Error getting unit');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Load user statistics
   */
  loadStatistics(startMonth, startYear, endMonth, endYear, success, error) {
    this.$log.debug('Loading user statistics...');

    this.$http.get(this.baseUrl + '/account/statistic?startMonth=' + startMonth + '&startYear=' + startYear + '&endMonth=' + endMonth + '&endYear=' + endYear).then((response) => {
      this.$log.debug(response.data);
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error Loading user statistics');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Reset user statistics
   */
  resetStatistics(startMonth, startYear, endMonth, endYear, success, error) {
    this.$log.debug('Resetting user statistics...');

    var data = {
      startMonth: startMonth,
      startYear: startYear,
      endMonth: endMonth,
      endYear: endYear
    };

    this.$http.put(this.baseUrl + '/account/statistic', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error resetting account statistic');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Start the login process
   */
  login(username: string, password: string, keepLoggedIn: boolean): Promise<AccountResponse> {
    return new Promise<AccountResponse>((resolve, reject) => {
      this.$log.debug('Logging in...');
      var data = {
        username: username,
        password: password,
        source: 'WEB'
      } as LoginRequest;
      // Reset JWT Token before login
      this.$http.defaults.headers.common.Authorization = undefined;
      this.$http.post(this.baseUrl + '/login', data).then((response) => {
        let loginResult = response.data as AccountResponse;

        if (loginResult.state === LoginTokenState.OK) {
          this.token = loginResult.token;
          if (this.token && this.token !== null) {
            this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
          }
          this.$log.debug('Successfully logged in: ' + this.token);

          if (keepLoggedIn) {
            this.localStorageService.set('token', this.token);
            this.localStorageService.set('loggedInFromAdmin', false);
          }
          this.localStorageService.set('firstAdminLogin', loginResult.firstAdminLogin);
        }

        resolve(loginResult);
      }, (err) => {
        this.$log.error('Error occured during login process:', err);
        reject(err);
      });
    });
  }

  isFirstAdminLogin() {
    var firstAdminLogin = this.localStorageService.get('firstAdminLogin');
    if (angular.isUndefined(firstAdminLogin)) {
      return false;
    }
    return firstAdminLogin;
  }

  finishFirstAdminLogin() {
    this.localStorageService.set('firstAdminLogin', false);
  }

  /**
   * Validate a 2FA request
   */
  validate2FA(username: string, key: string, oneTimeToken: string, keepLoggedIn: boolean, hasWeakPW: boolean): Promise<AccountResponse> {
    return new Promise<AccountResponse>((resolve, reject) => {
      this.$log.debug('Validate 2FA...');
      var data = {
        username: username,
        oneTimeToken: oneTimeToken,
        key: key,
        hasWeakPW: hasWeakPW
      } as SecondFactorValidation;

      this.$http.post(this.baseUrl + '/login/2fa', data).then((response) => {
        let loginResult = response.data as AccountResponse;
        this.token = loginResult.token;
        this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
        this.$log.debug('Successfully logged in: ' + this.token);

        if (keepLoggedIn) {
          this.localStorageService.set('token', this.token);
          this.localStorageService.set('loggedInFromAdmin', false);
        }

        resolve(loginResult);
      }, (err) => {
        this.$log.error('Error occured during login process:', err);
        reject(err);
      });
    });
  }


  /**
   * Logout and delete token
   */
  deleteToken() {
    this.$log.debug('Deleting local token...');
    this.localStorageService.remove('token');
    this.localStorageService.remove('loggedInFromAdmin');
    this.$http.defaults.headers.common.Authorization = '';
  };

  /**
   * LSet token
   */
  setToken(token, loggedInFromAdmin: boolean) {
    this.token = token;
    this.$log.debug('New token: ' + this.token);
    this.localStorageService.set('token', this.token);
    this.localStorageService.set('loggedInFromAdmin', loggedInFromAdmin);
    this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
  };

  getLoggedInFromAdmin() {
    var loggedInFromAdmin = this.localStorageService.get('loggedInFromAdmin');
    if (angular.isUndefined(loggedInFromAdmin)) {
      return false;
    }
    return loggedInFromAdmin;
  }

  resetLoginFromAdmin(resetRequest: AdminLoginResetRequest): Promise<AdminLoginResetResponse> {
    return new Promise<AdminLoginResetResponse>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/login/reset/admin', resetRequest).then((response) => {
        resolve(response.data as AdminLoginResetResponse);
      }, error => {
        reject(error);
        this.errorService.notifyWithText(error.data.message);
      })
    })

  }


  /**
   * Load token from datastore
   */
  loadToken() {
    var localToken = this.localStorageService.get('token');
    if (angular.isDefined(localToken) && localToken !== null) {
      this.token = localToken;
      this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
    }
    return localToken;
  };

  /**
   Returns the authentification token or undefined
   */
  getToken() {
    return this.token;
  };

  loadAccount(loggedInFromAdmin, success, error) {
    this.$log.debug('Loading account...');
    this.$http.get(this.baseUrl + '/account?loggedInFromAdmin=' + loggedInFromAdmin).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting account');
      this.handleErrorResponse(response, error);
    });

  };

  deleteOrganisation(userId: string, deleteOS: boolean): Promise<boolean> {
    this.$log.debug('Deleting account...');
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/admin/users/' + userId + '/?deleteOnlineService=' + deleteOS)
        .then(response => {
          resolve(true);
        }, errorResponse => {
          this.$log.error('Error deleting account');
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(false);
        })
    })
  }

  updateOrganisation(userId: string, name: string): Promise<boolean> {
    var data = {
      name: name
    } as UpdateUserRequest

    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + "/admin/users/" + userId, data).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      })
    })
  }

  /**
   * Update admin specific user settings
   */
  updateOrganisationSettingsForAdmin(userId: string, request: UserSettingsAsAdminRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/users/${encodeURIComponent(userId)}/settings`, request).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  getOrganisationLicenceInfo(userId: string): Promise<OrganisationLicenceSetAndAvailableData> {
    return new Promise<OrganisationLicenceSetAndAvailableData>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/users/' + userId + '/licence').then((response) => {
        resolve(response.data as OrganisationLicenceSetAndAvailableData);
      }, (errorResponse) => {
        reject(errorResponse.data.message)
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    })
  }

  deleteInputPluginById(inputPluginId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/input/${encodeURIComponent(inputPluginId)}`).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message)
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  updateOrganisationLicence(updateOrganisationRequest: UpdateOrganisationLicenseRequest, userId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/admin/users/' + userId + '/licence', updateOrganisationRequest).then(response => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message)
        this.errorService.notifyWithText(errorResponse.data.message);
      })
    })
  }

  //#####
  //ADDRESSBOOK
  //#####

  loadAddressbook(success, error) {
    this.$log.debug('Loading addressbook...');

    this.$http.get(this.baseUrl + '/addressbook/paginated').then((response) => {
      this.$log.debug(response.data);
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting addressbook');
      this.handleErrorResponse(response, error);
    });

  };

  /**
   * Load all persons paginated
   */
  loadPersons(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean): Promise<PersonsAddressbookResponse> {
    return new Promise<PersonsAddressbookResponse>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }

      let url = this.baseUrl + '/addressbook/paginated/simple?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }
      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PersonsAddressbookResponse);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }

  /**
   * Load all persons paginated
   */
  loadPersonsTrackingRep(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean): Promise<PaginatedDatabasePersonForTracking> {

    return new Promise<PaginatedDatabasePersonForTracking>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }
      let url = this.baseUrl + '/addressbook/paginated/tracking/simple?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }

      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PaginatedDatabasePersonForTracking);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }

  /**
   * Load all persons paginated
   */
  loadPersonsForReport(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean, userId: string): Promise<PaginatedPersonSearchQueryResult> {

    return new Promise<PaginatedPersonSearchQueryResult>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }
      let url = this.baseUrl + '/addressbook/paginated/report/simple?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }
      url = url + '&userId=' + userId;

      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PaginatedPersonSearchQueryResult);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }


  /**
   * Load all persons paginated
   */
  loadPersonsProvisionRep(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean): Promise<PaginatedDatabasePersonProvisioningRepresentation> {

    return new Promise<PaginatedDatabasePersonProvisioningRepresentation>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }
      let url = this.baseUrl + '/addressbook/paginated/assignProvisionings/simple?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }

      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PaginatedDatabasePersonProvisioningRepresentation);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }

  loadPersonsForQuickEdit(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean, alarmGroups: string): Promise<PersonQuickEditResponse> {
    return new Promise<PersonQuickEditResponse>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }
      let url = this.baseUrl + '/addressbook/paginated/quickedit?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;

      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }
      if (alarmGroups) {
        url = url + '&groupIds=' + alarmGroups
      }


      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PersonQuickEditResponse);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }


  /**
   * Load all persons paginated
   */
  loadPersonsAlarmGroupRep(page: number, filter: string, ordering: string, limit: number, field: string, filterShared: boolean): Promise<PaginatedDatabasePersonForAlarmGroups> {
    return new Promise<PaginatedDatabasePersonForAlarmGroups>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }

      let url = this.baseUrl + '/addressbook/paginated/alarmGroupEdit/simple?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }
      if (filterShared) {
        url = url + '&filterShared=true';
      }
      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PaginatedDatabasePersonProvisioningRepresentation);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }


  loadPersonsFull(page: number, filter: string, ordering: string, limit: number, field: string): Promise<PersonsAddressbookFullResponse> {
    return new Promise<PersonsAddressbookFullResponse>((resolve, reject) => {
      let limitResults = '20';
      if (limit) {
        limitResults = limit + '';
      }

      let url = this.baseUrl + '/addressbook/paginated?page=' + page + '&filter=' + filter + '&ordering=' + ordering + '&limit=' + limitResults;
      if (field) {
        url = url + '&field=' + field;
      }

      this.$http.get(url).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PersonsAddressbookFullResponse);
      }, (response) => {
        this.$log.error('Error getting addressbook');
        reject(response);
      });
    });
  }

  loadAMobiles(page: number, pageSize: number): Promise<AMobileAddressbookResponse> {
    return new Promise<AMobileAddressbookResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/amobiles/paginated?limit=' + pageSize + '&page=' + page).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as AMobileAddressbookResponse);
      }, (response) => {
        this.$log.error('Error getting amobiles');
        reject(response);
      });
    });
  }


  searchForAMobiles(query) {
    return this.$http.get(this.baseUrl + '/amobiles/search?query=' + encodeURIComponent(query)).then((response) => {
      this.$log.debug(response.data);
      return response.data;
    }, (response) => {
      this.$log.error('Error auto complete aMobiles');
      this.$log.error(response);
    });
  };

  loadAMobilesForBaseField(page: number, pageSize: number): Promise<AMobileAddressbookResponse> {
    return new Promise<AMobileAddressbookResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/amobiles/basefield/paginated?limit=' + pageSize + '&page=' + page).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as AMobileAddressbookResponse);
      }, (response) => {
        this.$log.error('Error getting amobiles');
        reject(response);
      });
    });
  }

  loadAlarmMonitors(page: number, pageSize: number, filter: AlarmMonitorType): Promise<AlarmMonitorAddressbookResponse> {
    let sFilter = '';
    if (filter) {
      sFilter = filter as string;
    }
    return new Promise<AlarmMonitorAddressbookResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/monitors/paginated?limit=' + pageSize + '&page=' + page + '&filter=' + sFilter).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as AlarmMonitorAddressbookResponse);
      }, (response) => {
        this.$log.error('Error getting AlarmMonitors');
        reject(response);
      });
    });
  }

  loadAlarmMonitorsForBaseField(page: number, pageSize: number, filter: AlarmMonitorType): Promise<AlarmMonitorAddressbookResponse> {
    let sFilter = '';
    if (filter) {
      sFilter = filter as string;
    }
    return new Promise<AlarmMonitorAddressbookResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/monitors/basefield/paginated?limit=' + pageSize + '&page=' + page + '&filter=' + sFilter).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as AlarmMonitorAddressbookResponse);
      }, (response) => {
        this.$log.error('Error getting AlarmMonitors');
        reject(response);
      });
    });
  }

  loadOverview(page: number): Promise<AddressBookOverViewData> {
    return new Promise<AddressBookOverViewData>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/overview?page=' + page).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as AddressBookOverViewData);
      }, (response) => {
        this.$log.error('Error getting addressbook overview');
        reject(response);
      });
    });
  };

  addPerson(displayname, lastname, firstname, email, osId, success, error) {
    this.$log.debug('Adding person...');

    var data = {
      displayName: displayname,
      lastName: lastname,
      firstName: firstname
    };
    if (angular.isDefined(osId)) {
      data['osId'] = osId;
    }
    if (angular.isDefined(email)) {
      data['personDetail'] = {
        aPagerPro: email,
        email: email
      };
    }
    this.$http.put(this.baseUrl + '/addressbook', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding person');
      this.handleErrorResponse(response, error);
    });

  };

  savePerson(person, success, error) {
    this.$log.debug('Saving person...');
    this.$http.post(this.baseUrl + '/addressbook', person).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving person');
      this.handleErrorResponse(response, error);
    });
  };

  savePersonNewFieldStructure(person: PersonNewFieldStructure): Promise<PersonNewFieldStructure> {
    return new Promise<PersonNewFieldStructure>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/addressbook/v2', person).then((response) => {
        resolve(response.data as PersonNewFieldStructure);
      }, (response) => {
        reject(response.data);
        this.errorService.notifyWithText(response.data.message);
      });
    });
  };

  deletePerson(request: DeletePersonRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$log.debug('Removing person...');

      this.$http.post(this.baseUrl + '/addressbook/delete', request).then(() => {
        resolve(true);
      }, (response) => {
        this.$log.error('Error removing person');
        reject(response.data);
      });
    });


  };

  /**
   * Change the state of a person
   */
  changePersonState(person, request, success, error) {
    this.$http.put(this.baseUrl + '/addressbook/person/' + encodeURIComponent(person.personID) + '/state', request).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error removing person');
      this.handleErrorResponse(response, error);
    });
  };


  resetLinkToAccessPersonPage(person: PersonNewFieldStructure, success, error) {
    this.$log.debug('Updating persons authentificationID...');

    this.$http.put(this.baseUrl + '/addressbook/person/' + encodeURIComponent(person.personID), undefined).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating authentificationID');
      this.handleErrorResponse(response, error);
    });

  };


  /**
   * Update availability for person
   */
  setAvailabilityForPerson(person: PersonNewFieldStructure, availability: EAvailabilityState, success, error) {
    this.$log.debug('Update availability for person...');
    this.$http.put(this.baseUrl + '/addressbook/person/' + encodeURIComponent(person.personID) + '/availability', {
      availability: availability
    }).then((response) => {
      person.availability = availability;
      person.timeOfUpdate = (new Date()).getTime();
      person.color = this.helperService.getColorForAvailability(availability);
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error setting availability for person');
      this.handleErrorResponse(response, error);
    });
  };

  sendEmailToPerson(data, success, error) {
    this.$log.debug('Sending link to person...');
    data.type = 'EXTERNAL_ACCESS';
    this.$http.post(this.baseUrl + '/help', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving person');
      this.handleErrorResponse(response, error);
    });
  };

  //#####
  //GROUPS
  //#####


  /**
   * Load all alarm groups
   */
  loadAlarmGroups(): Promise<AlarmGroup[]> {
    return new Promise<AlarmGroup[]>((resolve, reject) => {
      this.$log.debug('Loading alarm groups...');

      this.$http.get(this.baseUrl + '/alarmGroups').then((response) => {
        resolve(response.data as AlarmGroup[]);
      }, (error) => {
        reject(error);
      });
    });
  };


  /**
   * Load all alarm groups in a simple way
   */
  loadAlarmGroupsSimple(): Promise<AlarmGroupSimple[]> {
    return new Promise<AlarmGroupSimple[]>((resolve, reject) => {

      this.$http.get(this.baseUrl + '/alarmGroups/simple').then((response) => {
        resolve(response.data as AlarmGroupSimple[])
      }, (error) => {
        this.$log.error('Error getting groups', error);
        reject(error);
      });
    });
  };

  /**
   * Load all alarm groups in a simple way
   */
  loadAlarmGroupsSimpleProvisioning(): Promise<AlarmGroupSimple[]> {
    return new Promise<AlarmGroupSimple[]>((resolve, reject) => {

      this.$http.get(this.baseUrl + '/alarmGroups/simple/provisioning').then((response) => {
        resolve(response.data as AlarmGroupSimple[])
      }, (error) => {
        this.$log.error('Error getting groups', error);
        reject(error);
      });
    });
  };

  loadAlarmGroupsSimpleWithPerson(personID: string) {
    return new Promise<AlarmGroupSimple[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/alarmGroups/groups/' + encodeURIComponent(personID)).then((success) => {
        resolve(success.data as AlarmGroupSimple[]);

      }, (error) => {
        this.$log.error('Error getting groups for person with id ' + personID, error);
        reject(error);
      })
    })
  }

  loadSingleAlarmGroup(groupId: string): Promise<AlarmGroup> {
    return new Promise<AlarmGroup>((resolve, reject) => {

      this.$http.get(this.baseUrl + '/alarmGroups/' + encodeURIComponent(groupId)).then((response) => {
        resolve(response.data as AlarmGroup)
      }, (error) => {
        this.$log.error('Error getting groups', error);
        reject(error);
      });
    });
  };

  saveSingleAlarmGroupWithPersons(group: AlarmGroup): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/alarmGroups/' + encodeURIComponent(group.id) + '/persons', group).then((success) => {
          resolve(true);
        }, (error) => {
          reject(error.data.message);
          this.errorService.notifyWithText(error.data.message);
        }
      )
    })
  }

  saveAlarmGroupsForPerson(alarmGroups: AlarmGroupSimple[], personId: string): Promise<boolean> {
    var data = {
      alarmGroupsForPerson: alarmGroups
    } as AlarmGroupsForPersonRequest;

    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/alarmGroups/groups/' + encodeURIComponent(personId), data).then((success) => {
        resolve(true);
      }, (error) => {
        reject(error.data.message);
        this.errorService.notifyWithText(error.data.message);
      })
    })
  }

  /*
    Add a new group to the useres adressbook
  */
  addAlarmGroup(group, success, error) {
    this.$log.debug('Adding alarm group...');

    if (angular.isUndefined(group)) {
      //Invalid values, nothing to do
      this.$log.warn('Name not defined');
      return;
    }


    this.$http.post(this.baseUrl + '/alarmGroups', group).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding alarm group');
      this.handleErrorResponse(response, error);
    });

  };

  /*
    Remove an existing group from the users adressbook
  */
  deleteAlarmGroup(groupId: string, success, error) {
    this.$log.debug('Removing alarm group...');

    this.$http.delete(this.baseUrl + '/alarmGroups/' + encodeURIComponent(groupId)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error removing alarm group');
      this.handleErrorResponse(response, error);
    });

  };

  /**
   * Saves only name and color of group, not person mapping
   * @param group
   * @param success
   * @param error
   */

  saveSingleAlarmGroup(group: SingleAlarmGroupSaveRequest, success, error) {
    this.$log.debug('Saving edited alarm group...');
    this.$http.put(this.baseUrl + '/alarmGroups/' + encodeURIComponent(group.id), group).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving groups');
      this.handleErrorResponse(response, error);
    });
  }

  //#####
  //UNITS
  //#####

  /*
    Load the users units
  */
  loadUnits(success, error) {

    this.$http.get(this.baseUrl + '/units').then((response) => {
      this.$log.debug('Successfully loaded units...');
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error getting units');
      this.handleErrorResponse(response, error);
    });
  };

  /*
  Load the users units for unit view
*/
  loadUnitsForUnitsView(): Promise<any> {
    return new Promise(((resolve, reject) => {
      this.$http.get(this.baseUrl + '/units/user').then((response) => {
        this.$log.debug('Successfully loaded units...');
        resolve(response.data);
      }, (response) => {
        this.$log.error('Error getting units');
        reject(response.data.message)
      });
    }))

  };

  loadUnitsForBaseField(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/units/basefield').then((response) => {
        this.$log.debug('Successfully loaded units...');
        resolve(response.data);
      }, (response) => {
        this.$log.error('Error getting units');
        reject(response.data.message);
      });
    });
  }

  /**
   * Search for a list of units
   */
  searchForUnits(filter?: string, onlyOnwUnits?: boolean): Promise<UnitFilterResponse> {
    return new Promise<UnitFilterResponse>((resolve, reject) => {
      let url = this.baseUrl + '/units/search';
      if (onlyOnwUnits) {
        url = url + '?onlyOwn=true';
      } else {
        url = url + '?onlyOwn=false';
      }
      url = url + '&filter=' + encodeURIComponent(filter);
      this.$http.get(url).then((response) => {
        this.$log.debug('Successfully searched for units...');
        resolve(response.data as UnitFilterResponse);
      }, (error) => {
        reject(error);
      });
    });
  };


  /**
   * Get the number of currently deactivated units
   */
  loadDeactivatedUnits(): Promise<DeactivatedUnits> {
    return new Promise<DeactivatedUnits>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/units/deactivated').then((response) => {
        resolve(response.data as DeactivatedUnits);
      }, (error) => {
        reject(error);
      });
    });
  };

  /**
   * Get the number of failed availability rules
   */
  loadFailedAvailabilityRules(): Promise<FailedRules> {
    return new Promise<FailedRules>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/availability/rules/failed').then((response) => {
        resolve(response.data as FailedRules);
      }, (error) => {
        reject(error);
      });
    });
  };

  //#####
  //ALARM
  //#####

  /**
   Send an alarm
   */
  sendAlarm(alarm: AlarmRequestSimple, success, error) {
    this.$http.post(this.baseUrl + '/alarm', alarm).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error sending alarm');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Create a fake alarm request
   */
  sendFakeAlarm(alarm: AlarmFakerRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/alarm/fake', alarm).then(() => {
        resolve(true);
      }, (err) => {
        reject(err);
        this.errorService.notifyWithText(err.data.message);
      });
    });
  };

  /**
   Delete an existing alarm
   */
  deleteAlarm(id: string, success, error) {
    this.$http.delete(this.baseUrl + '/alarm/' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting alarm');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Load an existing alarm
   */
  loadAlarm(id: string): Promise<AlarmData> {
    return new Promise<AlarmData>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/alarm/' + encodeURIComponent(id)).then((response) => {
        resolve(response.data as AlarmData);
      }, (error) => {
        reject(error);
      });
    });

  };

  /**
   Delete all existing alarms
   */
  deleteAlarms(username, dayRange, success, error) {
    this.$http.delete(this.baseUrl + '/alarm?username=' + encodeURIComponent(username) + '&dayRange=' + dayRange).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting alarm');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete all trace logs
   */
  deleteTracelogs(unitId, dayRange): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/units/log/' + encodeURIComponent(unitId) + '?dayRange=' + dayRange).then((response) => {
        resolve();
      }, (response) => {
        this.$log.error('Error deleting trace logs');
        reject();
        this.errorService.notifyWithText(response.message);
      });
    });
  };

  /**
   Update an existing alarm
   */
  updateAlarmState(id, state, success, error) {
    this.$http.put(this.baseUrl + '/alarm/' + encodeURIComponent(id), state).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating alarm state');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Partially update an existing alarm
   */
  patchAlarm(id, data, success, error) {
    this.$http.patch(this.baseUrl + '/alarm/' + encodeURIComponent(id), data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error partially updating an alarm');
      this.handleErrorResponse(response, error);
    });
  };

  deleteVehicle(id): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/vehicles/' + id).then((response) => {
        resolve(true)
      }, error => {
        this.errorService.notifyWithText(error.data.message);
        reject(false);
      });
    });
  }

  /**
   * Partially update an existing vehicle
   */
  updateVehicle(vehicle: Vehicle): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      this.$http.patch(this.baseUrl + `/vehicles/${encodeURIComponent(vehicle.id)}`, vehicle).then((response) => {
        resolve(response.data as Vehicle);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  };

  addAccessToVehicle(user: User, vehicle: Vehicle): Promise<boolean> {
    var data = {userId: user.id} as PutVehicleAccessRequest
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/vehicles/' + vehicle.id + '/access', data).then((response) => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(false);
      });

    });
  }

  addAccessToAllVehicles(user: User): Promise<boolean> {
    var request = {
      userId: user.id
    } as PutVehicleAccessRequest
    return new Promise((resolve, reject) => {
      this.$http.post(this.baseUrl + '/vehicles/all/access/', request).then((response) => {
        resolve(true)
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(false);
      })
    })
  }

  removeAccessToAllVehicles(user: User): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/vehicles/all/access/' + user.id).then((response) => {
        resolve(true)
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(false);
      })
    })
  }

  removeAccessToVehicle(user: User, vehicle: Vehicle) {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/vehicles/' + vehicle.id + '/access/' + user.id).then((response) => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(false);
      })
    })
  }


  loadAlarmsPaginated(currentPage: number, limit: number, filter: string, ordering: string, dateFilter: number): Promise<AlarmDataPage> {
    return new Promise<AlarmDataPage>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/alarm/paginated/simple/?page=' + currentPage + '&limit=' + limit + '&filter=' + filter + '&ordering=' + ordering + '&startDate=' + dateFilter).then((response) => {
        resolve(response.data as AlarmDataPage);
      }, (error) => {
        reject(error);
      });
    });
  }


  /**
   Load availaibility from online Services
   */
  loadAvailability(success, error) {


    this.$http.get(this.baseUrl + '/availability').then((response) => {
      this.$log.debug('Successfully loaded availability from server...');
      success(response.data);
    }, (errorResponse) => {
      this.$log.error('Error getting availability');
      if (errorResponse.data.status === 403) {
        error(this.errorService.ERROR_IDS.NO_AVAILABILITY_RIGHT);
      } else {
        error(this.errorService.ERROR_IDS.NO_AVAILABILITY);
        this.errorService.notify(this.errorService.ERROR_IDS.NO_AVAILABILITY);
      }
    });
  };


  loadAvailabilityForAddressbook(forceReload?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/availability/addressbook?forceReload=' + forceReload).then((response) => {
        this.$log.debug('Successfully loaded availability from server...');
        resolve(response.data);
      }, (errorResponse) => {
        this.$log.error('Error getting availability');
        if (errorResponse.data.status === 403) {
          reject(this.errorService.ERROR_IDS.NO_AVAILABILITY_RIGHT);
        } else {
          reject(this.errorService.ERROR_IDS.NO_AVAILABILITY);
          this.errorService.notify(this.errorService.ERROR_IDS.NO_AVAILABILITY);
        }
      });
    });
  }

  /**
   Get feedback for alarm
   */
  loadFeedback(alarmId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/alarm/feedback/${alarmId}`).then((response) => {
        this.$log.debug('Successfully loaded feedback from server...');
        resolve(response.data);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  };

  /**
   Get all available cities.
   val: String= the search string
   */
  getCities(val) {
    return this.$http.get(this.baseUrl + '/address/cities?query=' + encodeURIComponent(val)).then((response) => {
      this.$log.debug(response);
      return response.data;
    });
  };

  /**
   Get all available streets.
   val: String= the search string
   city: String= the base city for the search
   */
  getStreets(city, val) {

    return this.$http.get(this.baseUrl + '/address/streets?city=' + encodeURIComponent(city) + '&query=' + encodeURIComponent(val)).then((response) => {
      this.$log.debug(response);
      return response.data;
    });
  };

  /**
   Get all available buildings.
   val: String= the search string
   */
  getBuildings(val) {

    return this.$http.get(this.baseUrl + '/address/buildings?query=' + encodeURIComponent(val)).then((response) => {
      this.$log.debug(response);
      return response.data;
    });
  };

  /**
   Get all available keywords.
   val: String= the search string
   */
  getKeywords(val: string) {
    return this.$http.get(this.baseUrl + '/keywords?query=' + encodeURIComponent(val)).then((response) => {
      return response.data;
    });
  };

  /**
   Get all available keywords, searched by description.
   val: String= the search string
   */
  getKeywordsByDescription(val: string) {
    return this.$http.get(this.baseUrl + '/keywords/description/?query=' + encodeURIComponent(val)).then((response) => {
      return response.data;
    });
  };

  //#####
  //EXTERNAL
  //#####

  loadPerson(username, id, auth, success, error) {
    this.$log.debug('Getting single person...');

    this.$http.get(this.baseUrl + '/external/person?id=' + encodeURIComponent(id) + '&auth=' + encodeURIComponent(auth) + '&username=' + username).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting single person');
      this.handleErrorResponse(response, error);
    });
  };

  loadPersonWithNewFieldStructure(personId: string): Promise<PersonNewFieldStructure> {

    return new Promise<PersonNewFieldStructure>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/person/' + encodeURIComponent(personId) + '/v2').then((response) => {
        resolve(response.data as PersonNewFieldStructure);
      }, (err) => {
        reject(err);
      });
    });
  };

  savePersonViaExternalAccess(username, auth, person, success, error) {
    this.$log.debug('Saving person...');
    this.$http.put(this.baseUrl + '/external/person?username=' + encodeURIComponent(username) + '&auth=' + encodeURIComponent(auth), person).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving person');
      this.handleErrorResponse(response, error);
    });
  };

  //#####
  //TEMPLATES
  //#####

  /**
   Get all available templates
   */
  getPipelineTemplates(success, error) {
    this.$http.get(this.baseUrl + '/templates/pipelines').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting templates');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get all available alarm templates
   */
  getAlarmTemplates(success, error) {
    this.$http.get(this.baseUrl + '/templates/alarm').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting alarm templates');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Auto Complete templates
   */
  searchForAlarmTemplates(query: string) {
    return this.$http.get(this.baseUrl + '/templates/alarm/search?filter=' + encodeURI(query)).then((response) => {
      this.$log.debug(response.data);
      return response.data;
    }, (response) => {
      this.$log.error('Error auto complete templates');
      this.$log.error(response);
    });
  };

  getAlarmTemplatesPaginated(page: number, filter: string): Promise<AlarmTemplatePaginatedResponse> {
    return new Promise<AlarmTemplatePaginatedResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/templates/alarm/paginated?filter=' + encodeURI(filter) + '&page=' + page).then(res => {
        resolve(res.data as AlarmTemplatePaginatedResponse);
      }, (response) => {
        this.$log.error('Error getting alarm templates');
        this.$log.error(response);
        reject(response);
      });
    });
  }

  getAlarmTemplatesByIdList(ids: string[]): Promise<AlarmTemplatePaginatedResponse> {
    return new Promise<AlarmTemplatePaginatedResponse>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/templates/alarm/ids', ids).then(res => {
        resolve(res.data as AlarmTemplatePaginatedResponse);
      }, (response) => {
        this.$log.error('Error getting alarm templates');
        this.$log.error(response);
        reject(response);
      });
    });
  }


  /**
   * Get the alarm template grid
   * @param {*} success
   * @param {*} error
   */
  loadAlarmTemplateGrids(success, error) {
    this.$http.get(this.baseUrl + '/templates/alarmgrid').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting alarm template grid');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Add  a new alarm template grid
   * @param {*} success
   * @param {*} error
   */
  addAlarmTemplateGrid(grid, success, error) {
    this.$http.post(this.baseUrl + '/templates/alarmgrid', grid).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding new alarm template grid');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update the alarm template grid
   * @param {*} success
   * @param {*} error
   */
  updateAlarmTemplateGrid(grid, success, error) {
    this.$http.put(this.baseUrl + '/templates/alarmgrid/' + encodeURIComponent(grid.id), grid).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating alarm template grid');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete a single alarm template by id
   */
  deleteAlarmTemplateById(id, success, error) {
    this.$http.delete(this.baseUrl + '/templates/alarm/' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting alarm template');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   Delete a single alarm template grid by id
   */
  deleteAlarmTemplateGridById(id, success, error) {
    this.$http.delete(this.baseUrl + '/templates/alarmgrid/' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting alarm template grid');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create a new alarm template
   */
  addAlarmTemplate(data, success, error) {
    this.$http.post(this.baseUrl + '/templates/alarm', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new alarm template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update existing alarm template
   */
  updateAlarmTemplate(data, success, error) {
    this.$http.put(this.baseUrl + '/templates/alarm/' + encodeURIComponent(data.id), data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new alarm template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get a single template by id
   */
  getPipelineTemplateById(id, success, error) {
    this.$http.get(this.baseUrl + '/templates/pipeline?id=' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create a new template
   */
  addPipelineTemplate(name, description, pipeline, success, error) {
    var data = {
      name: name,
      description: description,
      nodes: pipeline
    };
    this.$http.post(this.baseUrl + '/templates/pipeline', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete a single template by id
   */
  deletePipelineTemplateById(id, success, error) {
    this.$http.delete(this.baseUrl + '/templates/pipeline?id=' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get all available plugin templates
   */
  getPluginTemplates(success, error) {
    this.$http.get(this.baseUrl + '/templates/plugins').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting plugin templates');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create plugin from template
   */
  createPluginFromTemplate(id, success, error) {
    this.$http.get(this.baseUrl + '/templates/plugin?id=' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting plugin template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create a new template
   */
  addPluginTemplate(description, plugin, success, error) {
    var data = {
      description: description,
      pluginData: plugin.pluginData,
      name: plugin.name,
      abstractName: plugin.abstractName,
      note: plugin.note,
      quickEditActive: plugin.quickEditActive,
      quickEditSupported: plugin.quickEditSupported,
      storedPluginVersion: plugin.storedPluginVersion
    };
    this.$http.post(this.baseUrl + '/templates/plugin', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new plugin template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update an existing template
   */
  updatePluginTemplate(plugin, success, error) {
    this.$http.put(this.baseUrl + '/templates/plugin', plugin).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error editing a plugin template');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   Delete a single plugin template by id
   */
  deletePluginTemplateById(id, success, error) {
    this.$http.delete(this.baseUrl + '/templates/plugin?id=' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting template');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Add a new AM4 instance to the addressbook
   */
  addAM4(data: AlarmMonitor, defaultConfig: AlarmMonitorDefaultConfig, success, error) {
    let id = '';
    if (defaultConfig) {
      id = defaultConfig.id;
    }
    this.$http.post(this.baseUrl + '/monitors?defaultConfig=' + id, data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new AM4');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Add a new aMobile instance to the addressbook
   */
  addAMobile(data, success, error) {
    this.$http.post(this.baseUrl + '/amobiles', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new aMobile');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete an existing AM4
   */
  deleteAMById(id: string, success, error) {
    this.$http.delete(this.baseUrl + '/monitors/' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting AM');
      this.handleErrorResponse(response, error);
    });
  };

  refreshAMweb(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/monitors/${encodeURIComponent(id)}/refresh`).then(() => {
        resolve(true);
      }, (error) => {
        reject(error);
      });
    });
  }

  /**
   Delete an existing aMobile
   */
  deleteAMobileById(id: string, success, error) {
    this.$http.delete(this.baseUrl + '/amobiles/' + encodeURIComponent(id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting aMobile');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update an existing AM
   */
  editAM(am, success, error) {
    this.$http.put(this.baseUrl + '/monitors/' + encodeURIComponent(am.id), am).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error editing an existing AM');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update an existing AMobile
   */
  editAMobile(am, success, error) {
    this.$http.put(this.baseUrl + '/amobiles/' + encodeURIComponent(am.id), am).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error editing an existing aMobile');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   Send email to admin
   */
  sendEmailToAdmin(text, success, error) {
    var data = {
      text: text,
      type: 'HELP'
    };
    this.$http.post(this.baseUrl + '/help', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Could not send email to admin');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Add a new function to addressbook
   */
  addFunction(newFunction, success, error) {
    var data = {
      function: newFunction
    };
    this.$http.post(this.baseUrl + '/addressbook/functions', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new function');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete a function from addressbook
   */
  deleteFunction(toDeleteFunction: string, success: Function, error: Function) {
    this.$http.put(`${this.baseUrl}/addressbook/functionsGroups/`, {
      toRemove: toDeleteFunction,
      group: false
    } as DeleteFunctionGroupRequest).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new function');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Add a new function to addressbook
   */
  addGroup(newGroup, success, error) {
    var data = {
      group: newGroup
    };
    this.$http.post(this.baseUrl + '/addressbook/groups', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new group');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete a group from addressbook
   */
  deleteGroup(toDeleteGroup: string, success: Function, error: Function) {
    this.$http.put(`${this.baseUrl}/addressbook/functionsGroups/`, {
      toRemove: toDeleteGroup,
      group: true
    } as DeleteFunctionGroupRequest).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new function');
      this.handleErrorResponse(response, error);
    });
  };

  sendEmailWithLinkToPerson(person, success, error) {
    this.$http.get(this.baseUrl + '/help/persons/' + person.osId + '?mailPerson=' + person.data.aPagerPro + '&sharedSecret=' + person.sharedSecret).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new function');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get the REST protocols
   */
  getRestProtocols(page: number, limit: number, search: string, endDate: number, methods: string, success, error) {
    this.$http.get(this.baseUrl + `/restProtocols/all/simple?page=${page}&limit=${limit}&filter=${search}&endDate=${endDate}&methods=${methods}`).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading the current rest protocol');
      this.handleErrorResponse(response, error);
    });
  };

  getRestProtocolById(id: string, success, error) {
    this.$http.get(this.baseUrl + '/restProtocols/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading rest protocol information');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * Get the protocol
   */
  getProtocol(page: number, limit: number, search: string, filter: string, success, error) {
    this.$http.get(this.baseUrl + `/admin/protocol?page=${page}&limit=${limit}&search=${search}&filter=${filter}`).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading the current protocol');
      this.handleErrorResponse(response, error);
    });
  };

  loadAdditionalProtocolInformation(entryId, success, error) {
    this.$http.get(this.baseUrl + '/admin/protocol/additional?id=' + entryId).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading additional protocol information');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * Delete the protocol
   */
  clearProtocol(dayRange, success, error) {
    this.$http.delete(this.baseUrl + '/admin/protocol?dayRange=' + dayRange).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error clearing protocol');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * Check status of aPager PRO
   * @param {*} person
   * @param {*} success
   * @param {*} error
   */
  checkApagerStatus(person: DatabasePersonProvisioningRepresentation, success, error) {
    this.$http.get(this.baseUrl + '/apager/status?email=' + encodeURIComponent(person.apager) + '&personId=' + encodeURIComponent(person.id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error checking apager status');
      this.handleErrorResponse(response, error);
    });
  }

  checkApagerStatusPromise(person: DatabasePersonProvisioningRepresentation): Promise<DatabasePersonProvisioningRepresentation> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/apager/status?email=' + encodeURIComponent(person.apager) + '&personId=' + encodeURIComponent(person.id)).then((response) => {
        resolve(response.data as DatabasePersonProvisioningRepresentation);
      }, (response) => {
        this.$log.error('Error checking apager status');
        reject(response.data.message);
      });
    });
  }

  /**
   Load all availability rules
   */
  loadAvailabilityRules(success, error) {

    this.$http.get(this.baseUrl + '/availability/rules').then((response) => {
      this.$log.debug('Successfully loaded availavility rules...');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting rules');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   Create a new availability rule
   */
  createAvailabilityRule(rule, success, error) {
    this.$log.debug('Create a new rule...');
    this.$log.debug(rule);

    this.$http.post(this.baseUrl + '/availability/rules', rule).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error creating rule');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   Delete existing availability rule
   */
  deleteAvailabilityRule(rule, success, error) {
    this.$log.debug('Delete an existing rule...');

    this.$http.delete(this.baseUrl + '/availability/rules/' + rule.id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting rule');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   Update a existing availability rule
   */
  saveAvailabilityRule(rule, success, error) {
    this.$log.debug('Save an existing rule...');
    delete rule.isValid;
    this.$http.put(this.baseUrl + '/availability/rules/', rule).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving rule');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   Get weekly availability
   */
  loadWeeklyAvailability(person, kw, year, success, error) {
    this.$log.debug('Loading weekly availability...');
    this.$http.get(this.baseUrl + '/availabilityStatistic/person/' + person.id + '/week/' + kw + '/year/' + year)
      .then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error getting availability');
        this.handleErrorResponse(response, error);
      });
  }

  /**
   Get weekly availability
   */
  loadWeeklyAvailabilityByDate(person, timestamp, success, error) {
    this.$log.debug('Loading weekly availability...');

    this.$http.get(this.baseUrl + '/availabilityStatistic/person/' + person.id + '/weekByDate/' + timestamp)
      .then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error getting availability');
        error(response);
      });
  }

  /**
   Get monthly availability
   */
  loadMonthlyAvailability(person, month, year, success, error) {
    this.$log.debug('Loading month availability...');

    this.$http.get(this.baseUrl + '/availabilityStatistic/person/' + person.id + '/month/' + month + '/year/' + year)
      .then((response) => {
        this.handleSuccessResponse(response, success);
      }, (response) => {
        this.$log.error('Error getting availability');
        error(response);
      });
  }

  /* Change password for user
   * @param {*} person
   * @param {*} success
   * @param {*} error
   */
  changePassword(data, success, error) {
    this.$http.put(this.baseUrl + '/account', data).then((response) => {
      this.localStorageService.remove('token');
      this.$http.defaults.headers.common.Authorization = 'JWT ' + response.data['token'];
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error changing password');
      this.handleErrorResponse(response, error);
    });
  }

  changeAdminPassword(data, success, error) {
    this.$http.put(this.baseUrl + '/account/admin', data).then((response) => {
      this.localStorageService.remove('token');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error changing password');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * Change the app password
   */
  changeAppPassword(newAppPassword: string, mode: ChangePasswordMode): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/account', {
        newAppPassword: newAppPassword,
        type: mode
      }).then(() => {
        resolve(true);
      }, (response) => {
        this.$log.error('Error changing app password');
        this.$log.error(response);
        reject(response);
      });
    });
  }

  /**
   * Update user settings
   */
  updateUserSettings(settings, success, error) {
    this.$log.debug('Update user settings...');
    this.$http.put(this.baseUrl + '/account/settings', settings).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating user settings');
      this.handleErrorResponse(response, error);
    });
  }

  reset2FaForPerson(loginName: string, success, error) {
    this.$http.get(this.baseUrl + '/addressbook/reset2fa/' + encodeURIComponent(loginName)).then(response => {
      this.handleSuccessResponse(response, success);
    }, (errorResponse) => {
      this.$log.error('Error resetting 2FA for person "' + loginName + '"');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  // ### OBJECTS ###

  /**
   * Retrieve all my alarm objects
   * @param {*} success
   * @param {*} error
   */
  getAllAlarmObjects(page, limit, success, error) {
    this.$http.get(this.baseUrl + '/objects?page=' + page + '&limit=' + limit).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving all alarm objects');
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * Search for specific alarm objects
   * @param {} filterString
   * @param {*} success
   * @param {*} error
   */
  searchForAlarmObject(page, limit, filterString, queryFilter, success, error) {
    this.$http.get(this.baseUrl + '/objects?filter=' + filterString + '&columnFilter=' + queryFilter + '&page=' + page + '&limit=' + limit).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving all filtered list of alarm objects');
      this.handleErrorResponse(response, error);
    });
  };

  searchForAlarmObjectAsSlice(page: number, limit: number, filter: string, coloumnFilter: string, sort: string, descending: boolean): Promise<AlarmObjectTableRowEntry> {
    return new Promise<AlarmObjectTableRowEntry>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/objects/slice?filter=${filter}&columnFilter=${coloumnFilter}&page=${page}&limit=${limit}&sort=${sort}&descending=${descending}`).then((response) => {
        resolve(response.data as AlarmObjectTableRowEntry);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      })
    })
  }

  /**
   * Retrieve a single alarm object
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  getAlarmObjectById(id, success, error) {
    this.$http.get(this.baseUrl + '/objects/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving object with id: ' + id);
      this.handleErrorResponse(response, error);
    });
  };


  /**
   * Delete a single alarm object
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  deleteAlarmObjectById(id, success, error) {
    this.$http.delete(this.baseUrl + '/objects/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting object with id: ' + id);
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete a sub object
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  deleteSubObject(parentId, subId, keepInDb, success, error) {
    this.$http.delete(this.baseUrl + '/objects/' + subId + '?keepInDb=' + keepInDb + '&parentId=' + parentId).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting object with subId: ' + subId);
      this.handleErrorResponse(response, error);
    });
  };


  /**
   * Add a new single alarm object
   * @param {*} name
   * @param {*} success
   * @param {*} error
   */
  addAlarmObject(name, success, error) {
    this.$http.post(this.baseUrl + '/objects/', {
      name: name
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new object ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Add a new single alarm object
   * @param {*} name
   * @param {*} success
   * @param {*} error
   */
  addSubObject(name, id, success, error) {
    this.$http.post(this.baseUrl + '/objects/', {
      name: name,
      subType: 'SUB',
      parentId: id
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new object ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update a existing alarm object
   * @param {*} alarmObject
   * @param {*} success
   * @param {*} error
   */
  updateAlarmObject(alarmObject, success, error) {
    this.$http.put(this.baseUrl + '/objects/', alarmObject).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating a object ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get the document file uploader
   */
  createDocumentObjectUploader() {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/objects/document';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'PDF',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'image/jpeg') {
          return true;
        } else if (item.type === 'image/png') {
          return true;
        } else if (item.type === 'application/pdf') {
          return true;
        } else {
          return false;
        }
      }
    }];

    return uploader;
  }

  /**
   * Get the document file uploader
   */
  createUploader(alarmObject) {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/objects/' + alarmObject.id + '/files/?name=';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'PDF',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'image/jpeg') {
          return true;
        } else if (item.type === 'image/png') {
          return true;
        } else if (item.type === 'application/pdf') {
          return true;
        } else {
          return false;
        }
      }
    }];
    return uploader;
  }

  /**
   * Get the directioncard file uploader
   */
  createDirectioncardUploader(alarmObject) {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/objects/' + alarmObject.id + '/directioncards/?fileName=';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'PDF + ZIP',
      // A user-defined filter
      fn: (item) => {
        if (item.type === 'application/zip' || item.type === 'application/x-zip-compressed') {
          return true;
        } else if (item.type === 'application/pdf') {
          return true;
        } else {
          // Notify users
          let msg = this.$translate.instant('ASSETS.ERROR_FILE_TYPE');
          this.errorService.notifyWithText(msg);
          return false;
        }
      }
    }];
    return uploader;
  }

  /**
   * Get the document file uploader
   */
  createImageAssetUploader() {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/objects/icons';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'Symbole',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'image/jpeg') {
          return true;
        } else if (item.type === 'image/png') {
          return true;
        } else {
          return false;
        }
      }
    }];
    return uploader;
  }

  /**
   * Get the logo file uploader
   */
  createLogoUploader() {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/account/logo';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'Logo',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'image/jpeg') {
          return true;
        } else if (item.type === 'image/png') {
          return true;
        } else {
          return false;
        }
      }
    }];
    return uploader;
  }

  /**
   * Get the image file uploader
   */
  createImageUploader() {
    var uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + '/admin/settings/logo';
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'Logo',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'image/jpeg') {
          return true;
        } else if (item.type === 'image/png') {
          return true;
        } else {
          return false;
        }
      }
    }];
    return uploader;
  }

  deleteImage(type: UploadType) {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/settings/logo?type=${type}`)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  /**
   * Get the CSV file uploader
   */
  createCSVUploader(path: string) {
    const uploader = new this.FileUploader();
    uploader.url = this.getBaseUrl() + path;
    uploader.removeAfterUpload = true;
    uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    };
    uploader.filters = [{
      name: 'CSV',
      // A user-defined filter
      fn: function (item) {
        if (item.type === 'text/csv') {
          return true;
        }
        return false;
      }
    }];
    return uploader;
  }

  /**
   * Get JWT auth header
   */
  getAuthHeader() {
    return this.$http.defaults.headers.common.Authorization;
  }


  /**
   * Delete a existing document
   * @param {*} alarmObject
   * @param {*} document
   * @param {*} success
   * @param {*} error
   */
  deleteDocument(alarmObject, document, success, error) {
    this.$http.delete(this.baseUrl + '/objects/' + alarmObject.id + '/files/' + document.id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting file: ' + document.id);
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete a existing directioncard
   * @param {*} alarmObject
   * @param {*} directioncard
   * @param {*} success
   * @param {*} error
   */
  deleteDirectioncard(alarmObject, directioncard, success, error) {
    this.$http.delete(this.baseUrl + '/objects/' + alarmObject.id + '/directioncards/' + directioncard.fileName).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting file: ' + directioncard.fileName);
      this.handleErrorResponse(response, error);
    });
  };

  // ### ROADBLOCKS ###
  /**
   * Retrieve all roadblocks
   * @param {*} success
   * @param {*} error
   */
  getAllRoadblocks(success, error) {
    this.$http.get(this.baseUrl + '/objects/roadblocks').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving all roadblocks');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete a single roadblock
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  deleteRoadblockById(id, success, error) {
    this.$http.delete(this.baseUrl + '/objects/roadblocks/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting roadblock with id: ' + id);
      this.handleErrorResponse(response, error);
    });
  }

  /**
   * resend roadblocks
   * @param {*} success
   * @param {*} error
   */
  resendRoadblocks(success, error) {
    this.$http.post(this.baseUrl + '/objects/roadblocknotify', undefined).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error sending roadblocks ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update a existing roadblock
   * @param {*} roadblock
   * @param {*} success
   * @param {*} error
   */
  updateRoadblock(roadblock, sendNotification: boolean, success, error) {
    this.$http.put(`${this.baseUrl}/objects/roadblocks?sendNotification=${sendNotification}`, roadblock).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating a roadblock ');
      this.handleErrorResponse(response, error);
    });
  }

  getRoadblockDetourMatching(routeShape: Roadblock): Promise<DetourMatch> {
    return new Promise<DetourMatch>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/objects/roadblocks/detourmatching', routeShape).then((response) => {
        resolve(response.data as DetourMatch);
      }).catch((errorResponse) => {
        reject(errorResponse);
      });
    });

  }

  /**
   * Change log level temporary
   */
  changeLogLevel(newLogLevel, success, error) {
    this.$http.put(this.baseUrl + '/admin/logging', {
      logLevelStr: newLogLevel
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating a roadblock ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Retrieve all existing image assets
   * @param {*} success
   * @param {*} error
   */
  getAllImageAssets(success, error) {
    this.$http.get(this.baseUrl + '/objects/icons').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving all image icons');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete a single image asset
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  deleteImageAssetById(id, success, error) {
    this.$http.delete(this.baseUrl + '/objects/icons/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting image asset with id: ' + id);
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Retrieve all existing POIs
   * @param {*} success
   * @param {*} error
   */
  getAllPOIs(success, error) {
    this.$http.get(this.baseUrl + '/objects/paths').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error retrieving all POIs');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete a single image asset
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  deletePOIById(id, success, error) {
    this.$http.delete(this.baseUrl + '/objects/paths/' + id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting POI with id: ' + id);
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Add a new POI
   * @param {*} name
   * @param {*} success
   * @param {*} error
   */
  addPOI(name, assignedObject, success, error) {
    var data = {
      name: name
    };
    if (angular.isDefined(assignedObject)) {
      data['objectId'] = assignedObject.id;
    }
    this.$http.post(this.baseUrl + '/objects/paths', data).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new POI ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update a existing POI
   * @param {*} roadblock
   * @param {*} success
   * @param {*} error
   */
  updatePOI(poi, success, error) {
    this.$http.put(this.baseUrl + '/objects/paths', poi).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating a POI ');
      this.handleErrorResponse(response, error);
    });
  };

  changeLoginAsAdmin(username, success, error) {
    this.$http.get(this.baseUrl + '/admin/token?username=' + encodeURIComponent(username)).then((response) => {
      this.$log.debug(response.data);
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting login');
      this.handleErrorResponse(response, error);
    });
  };

  getAdminToken(loggedInFromAdmin: boolean): Promise<UserTokenResponse> {
    return new Promise<UserTokenResponse>((resolve, reject) => {
      if (!this.token) {
        reject("Es liegt kein gültiger Token vor.");
        this.errorService.notifyWithText("Es liegt kein gültiger Token vor.");
      }
      this.$http.get(this.baseUrl + '/account/admin/token?loggedInFromAdmin=' + loggedInFromAdmin).then((response) => {
        resolve(response.data as UserTokenResponse);
      }, errorResponse => {
        reject(errorResponse.data.message);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });

  }

  changeLoginAsPerson(username, success, error) {
    this.$http.get(this.baseUrl + '/login/switchUser?userId=' + encodeURIComponent(username)).then((response) => {
      this.$log.debug(response.data);
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting login');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get all online services
   */
  loadOnlineServices(success, error) {
    this.$log.debug('Loading online services...');
    this.$http.get(this.baseUrl + '/addressbook/onlineservices').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting onlineservices');
      this.handleErrorResponse(response, error);
    });
  };

  loadOnlineServicesForBaseField(): Promise<any[]> {
    this.$log.debug('Loading online services...');
    return new Promise<any[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/basefield/onlineservices').then((response) => {
        resolve(response.data as any[]);
      }, (response) => {
        this.$log.error('Error getting onlineservices');
        reject(response.data.message);
      });
    });
  };

  //### API KEYS ###

  /**
   Load all api keys
   */
  loadApiKeys(success, error) {

    this.$http.get(this.baseUrl + '/addressbook/api').then((response) => {
      this.$log.debug('Successfully loaded api keys...');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting api keys');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Create a new api key
   */
  createApiKey(key, success, error) {
    this.$log.debug('Create a new API key...');
    this.$log.debug(key);

    this.$http.post(this.baseUrl + '/addressbook/api', key).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error creating api key');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Generate a new self registration key
   */
  regenerateSelfRegistrationKey(success, error) {
    this.$http.get(this.baseUrl + '/addressbook/selfregistration').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error generating new self registration key');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get registration information
   */
  getSelfRegistration(auth, success, error) {
    this.$http.get(this.baseUrl + '/external/person/register?auth=' + auth).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting self registration information');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   * Get registration information
   */
  registerViaExternal(request, auth, success, error) {
    this.$http.post(this.baseUrl + '/external/person/register?auth=' + auth, request).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error sending self registration');
      this.handleErrorResponse(response, error);
    });
  };


  /**
   * Change the revision mode in FE2
   */
  changeRevisionMode(isInRevisionMode, success, error) {
    this.$http.put(this.baseUrl + '/admin/revision', {
      revision: isInRevisionMode
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error changing revision mode');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete existing api key
   */
  deleteApiKey(key, success, error) {
    this.$log.debug('Delete an existing api key...');

    this.$http.delete(this.baseUrl + '/addressbook/api/' + encodeURIComponent(key.id)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting api key');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update a existing api key
   */
  saveApiKey(key, success, error) {
    this.$log.debug('Save an existing key...');
    this.$http.put(this.baseUrl + '/addressbook/api/', key).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving api');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get maps usage
   */
  loadMapsUsage(success, error) {
    this.$http.get(this.baseUrl + '/admin/map/usage/').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading maps usage');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get remaining SMS
   */
  loadRemainingSMS(success, error) {
    this.$http.head(this.baseUrl + '/admin/sms/remaining').then((response) => {
      var data = {
        count: response.headers('number-of-remaining-sms')
      };
      this.handleSuccessResponse(data, success);
    }, (errResponse) => {
      this.$log.error('Error loading remaining SMS');
      this.handleErrorResponse(errResponse, error);
    })
  }

  /**
   Get SMS usage
   */
  loadSMSUsage(year: number, success, error) {
    this.$http.get(this.baseUrl + '/admin/sms/usage?year=' + year).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error loading SMS usage');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Send a test alarm
   * @param {} person
   * @param {*} isInfoAlarm
   * @param {*} success
   * @param {*} error
   */
  sendTestAlarm(person: PersonNewFieldStructure, isInfoAlarm: boolean, success, error) {
    this.$http.post(this.baseUrl + '/addressbook/test/', {
      infoAlarm: isInfoAlarm,
      personID: person.personID,
      aPagerPro: person.apagerSettings.apagerProValue
    }).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error saving api');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Geocode a single address
   */
  geocode(location, success, error) {
    this.$http.get(this.baseUrl + '/objects/geocode?location=' + encodeURIComponent(location)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error during geocode');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get all layers
   */
  loadLayers(success, error) {
    this.$http.get(this.baseUrl + '/hydrants').then((response) => {
      this.$log.debug('Successfully loaded layers...');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting layers');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get hydrants from a specific layer
   */
  loadHydrants(layer, success, error) {
    this.$http.get(this.baseUrl + '/hydrants/' + encodeURIComponent(layer.name) + '/hydrants').then((response) => {
      this.$log.debug('Successfully loaded hydrants...');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting hydrants');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Get GeoJSON from a specific layer
   */
  loadGeoJsonFromLayer(layer, success, error) {
    this.$http.get(this.baseUrl + '/hydrants/' + encodeURIComponent(layer.name) + '/geojson').then((response) => {
      this.$log.debug('Successfully loaded GeoJson...');
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting GeoJson');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Update an existing layer
   */
  updateLayer(layer, success, error) {
    this.$log.debug('Update existing layer...');
    this.$log.debug(layer);

    this.$http.put(this.baseUrl + '/hydrants/' + encodeURIComponent(layer.name), layer).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating layer');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   Delete existing layer
   */
  deleteLayerByName(layerName, success, error) {
    this.$log.debug('Delete an layer by name: ' + layerName);

    this.$http.delete(this.baseUrl + '/hydrants/' + encodeURIComponent(layerName)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting existing layer');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Search for dangerous goods by UN number
   * val: String= the search string
   */
  getDangerousGoodsByUnNumber(val) {
    return this.$http.get(this.baseUrl + '/dangerousGoods/un?query=' + encodeURIComponent(val)).then((response) => {
      this.$log.debug(response);
      return response.data;
    });
  };

  /**
   * Search for dangerous goods by UN number
   * val: String= the search string
   */
  getDangerousGoodsByName(val) {
    return this.$http.get(this.baseUrl + '/dangerousGoods/name?query=' + encodeURIComponent(val)).then((response) => {
      this.$log.debug(response);
      return response.data;
    });
  };


  /**
   * Get events for a specific time range
   * @param {} range
   * @param {*} success
   * @param {*} error
   */
  getEventsInTimeFrame(success, error) {
    this.$http.get(`${this.baseUrl}/objects/events`).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (errorResponse) => {
      this.$log.error('Error getting events');
      this.handleErrorResponse(errorResponse, error);
    })
  };

  /**
   * Get the number of current alarms
   * @param {} shiftBook If true, it will only return the number of alarms for shift book
   */
  getNumberOfCurrentAlarms(shiftBook: boolean, success, error) {
    this.$http.head(`${this.baseUrl}/alarm?shiftBook=${shiftBook}`).then((response) => {
      var data = {
        count: response.headers('number-of-alarms'),
        countError: response.headers('number-of-alarms-with-error')
      };
      this.handleSuccessResponse(data, success);
    }, (errorResponse) => {
      this.$log.error('Error getting current number of alarms');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  /**
   * Get the number of current events
   * @param {} success
   * @param {*} error
   */
  getNumberOfCurrentEvents(success, error) {
    this.$http.head(`${this.baseUrl}/objects/events`).then((response) => {
      this.handleSuccessResponse(response.headers('number-of-events'), success);
    }, (errorResponse) => {
      this.$log.error('Error getting current number of alarms');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  /**
   * Get the number of current roadblocks
   * @param {} success
   * @param {*} error
   */
  getNumberOfCurrentRoadblocks(success, error) {
    this.$http.head(`${this.baseUrl}/objects/roadblocks/shiftbook?onlyImportant=true`).then((response) => {
      this.handleSuccessResponse(response.headers('number-of-roadblocks'), success);
    }, (errorResponse) => {
      this.$log.error('Error getting current number of roadblocks');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  /**
   * Get the number of current vehicle faults and vehicles in status 6
   * @param {} success
   * @param {*} error
   */
  getNumberOfVehicleFaultsAndStatus6(success, error) {
    this.$http.head(this.baseUrl + '/vehicles').then((response) => {
      var data = {
        countFaults: response.headers('number-of-vehicle-faults'),
        countStatus6: response.headers('number-of-vehicle-state-6')
      };
      this.handleSuccessResponse(data, success);
    }, (errorResponse) => {
      this.$log.error('Error getting current number of roadblocks');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  getShiftbookCategories(success, error) {
    this.$http.get(this.baseUrl + '/shiftbook/category').then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  deleteShiftbookCategory(category, success, error) {
    this.$http.delete(this.baseUrl + '/shiftbook/category/' + encodeURIComponent(category.categoryId)).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  editShiftbookCategory(category, success, error) {
    this.$http.put(this.baseUrl + '/shiftbook/category', category).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  createShiftbookCategory(categoryName, success, error) {
    this.$http.post(this.baseUrl + '/shiftbook/category', {
      categoryName: categoryName
    }).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  getShiftbookCustomField(success, error) {
    this.$http.get(this.baseUrl + '/shiftbook/customfield').then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  deleteShiftbookCustomField(field, success, error) {
    this.$http.delete(this.baseUrl + '/shiftbook/customfield/' + encodeURIComponent(field.customFieldId)).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  editShiftbookCustomField(field, success, error) {
    this.$http.put(this.baseUrl + '/shiftbook/customfield', field).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  createShiftbookCustomField(fieldName, success, error) {
    this.$http.post(this.baseUrl + '/shiftbook/customfield', {
      fieldName: fieldName
    }).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.handleErrorResponse(response, error);
    });
  }

  getShiftbookRoadblocks(start, end, onlyImportant, success, error) {
    this.$http.get(this.baseUrl + '/objects/roadblocks/shiftbook?startDate=' + start + '&endDate=' + end + '&onlyImportant=' + onlyImportant).then((response) => {
      this.handleSuccessResponse(response.data, success)
    }, (errorResponse) => {
      this.handleErrorResponse(errorResponse, error);
    })
  }

  /**
   * Update a custom field
   */
  updateCustomField(field, success, error) {
    this.$http.put(this.baseUrl + '/shiftbook/customfield', field).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating custom field');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get all messages for specific shiftbook category
   */
  getShiftbookMessages(categoryId, success, error) {
    this.$http.get(this.baseUrl + '/shiftbook/message/' + encodeURI(categoryId)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error get shiftbook category messages');
      this.handleErrorResponse(response, error);
    });
  };

  /* SHIFTBOOK */
  /**
   * Add a new shiftbook message
   */
  addNewShiftbookMessage(message, success, error) {
    var request = {
      author: message.author,
      messageText: message.messageText,
      creationDate: message.creationDate
    };
    this.$http.post(this.baseUrl + '/shiftbook/message/' + encodeURI(message.categoryId) + '/messages', request).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding shiftbook category messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update existing shiftbook message
   */
  updateShiftbookMessage(message, success, error) {
    this.$http.put(this.baseUrl + '/shiftbook/message/' + encodeURI(message.categoryId) + '/messages', message).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating shiftbook category messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete existing shiftbook message
   */
  deleteShiftbookMessage(message, success, error) {
    this.$http.delete(this.baseUrl + '/shiftbook/message/' + encodeURI(message.categoryId) + '/messages/' + encodeURI(message.messageId)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting shiftbook category messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Auto Complete persons
   */
  searchForPersons(query, userid?: string) {
    var url = this.baseUrl + '/addressbook/search?query=' + encodeURIComponent(query);
    if (userid) {
      url = url + '&userId=' + encodeURIComponent(userid);
    }

    return this.$http.get(url).then((response) => {
      this.$log.debug(response.data);
      return response.data;
    }, (response) => {
      this.$log.error('Error auto complete addressbook');
      this.$log.error(response);
    });
  };

  loadMinimalPerson(personId: string): Promise<PersonSearchQueryResult> {
    return new Promise<PersonSearchQueryResult>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/addressbook/${personId}/minimal`).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as PersonSearchQueryResult);
      }, (response) => {
        this.$log.error('Error auto complete addressbook');
        this.$log.error(response);
        reject(response.data.message)
      });
    });
  };

  searchForPersonsInAdmin(page: number, limit: number, query: string): Promise<AdminAddressbookResponse> {
    return new Promise<AdminAddressbookResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/addressbook?page=' + page + '&limit=' + limit + '&query=' + encodeURIComponent(query)).then((response) => {
        resolve(response.data as AdminAddressbookResponse);
      }, (err) => {
        reject(err);
      });
    });
  }

  /**
   * Load a single person by its ID
   */
  loadPersonById(personID: string): Promise<Person> {
    return new Promise<Person>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/person/' + encodeURIComponent(personID)).then((response) => {
        resolve(response.data as Person);
      }, (err) => {
        reject(err);
      });
    });
  }


  /**
   * Auto Complete vehicles
   */
  searchForVehiclesAndSirens(query: string) {
    return this.$http.get(this.baseUrl + '/vehicles/search?query=' + encodeURI(query)).then((response) => {
      this.$log.debug(response.data);
      return response.data;
    }, (response) => {
      this.$log.error('Error auto complete vehicles');
      this.$log.error(response);
    });
  };

  /**
   * Auto Complete vehicles
   */
  searchForVehicles(query: string) {
    return this.$http.get(this.baseUrl + '/vehicles/search?vehicleRequestType=VEHICLES&query=' + encodeURI(query)).then((response) => {
      this.$log.debug(response.data);
      return response.data;
    }, (response) => {
      this.$log.error('Error auto complete vehicles');
      this.$log.error(response);
    });
  };

  /**
   * search for vehicles accessible for organisation with given query.
   * @param userId Organisations id to search vehicles in
   * @param query search value from user for matching vehicles
   * @param limit The max number of results
   */
  searchForVehiclesAccessibleForUser(query: string, userId: string, limit: number, onlyAssigned?: boolean) {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${encodeURI(userId)}/search?query=${encodeURI(query)}&limit=${limit}&onlyAssigned=${onlyAssigned}`).then((response) => {
        this.$log.debug(response.data);
        resolve(response.data);
      }, (error) => {
        this.$log.error('Error auto complete vehicles');
        this.$log.error(error);
        reject(error);
      });
    });
  };


  searchForVehiclesAsPromise(query: string): Promise<VehiclesPaginatedResponse> {
    return new Promise<VehiclesPaginatedResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/search?query=' + encodeURI(query)).then((response) => {
        resolve(response.data as VehiclesPaginatedResponse);
      }, (error) => {
        this.$log.error('Error auto complete vehicles');
        reject(error);
      });
    });
  };

  /**
   * Swap to vehicles
   */
  swapVehicles(vehicle1: Vehicle, vehicle2: Vehicle, options: VehicleSwapOptions): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/swap?vehicle1=${encodeURIComponent(vehicle1.id)}&vehicle2=${encodeURIComponent(vehicle2.id)}` +
        `&swapParkingPosition=${options.swapParkingPositions}` +
        `&swapCodes=${options.swapCodes}` +
        `&swapOnlyFirstCode=${options.swapOnlyFirstCodes}` +
        `&swapVehicleData=${options.swapVehicleData}`)
        .then((response) => {
          resolve(true);
        }, (response) => {
          this.$log.error('Error swaping vehicles');
          this.$log.error(response);
          reject(response);
        });
    });
  }


  // Messages
  /**
   * Get all planned messages
   */
  getMessages(success, error) {
    this.$http.get(this.baseUrl + '/messages/').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Add a new message
   */
  addMessage(message, success, error) {
    this.$http.post(this.baseUrl + '/messages/', message).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error adding a new planned message');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update existing message
   */
  updateMessage(message, success, error) {
    this.$http.put(this.baseUrl + '/messages/' + encodeURI(message.messageId), message).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error updating planned messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete existing message
   */
  deleteMessage(message, success, error) {
    this.$http.delete(this.baseUrl + '/messages/' + encodeURI(message.messageId)).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting message');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Clear all old messages
   */
  clearMessages(success, error) {
    this.$http.delete(this.baseUrl + '/messages?onlyOld=true').then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error getting messages');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete existing online service person
   */
  deleteOnlineServicePerson(person, success, error) {
    this.$http.delete(this.baseUrl + '/availability/person/' + person.id).then((response) => {
      this.handleSuccessResponse(response, success);
    }, (response) => {
      this.$log.error('Error deleting online service person');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Trigger the transmissio nof all markers to AM4
   */
  transmitMarkerToAM4(success, error) {
    this.$http.post(this.baseUrl + '/objects/pathsnotify', undefined).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error sending marker ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Check if there are enough licences are available
   */
  checkIfLicenceAreAvailable(licenceType, success, error) {
    this.$http.get(this.baseUrl + '/amobiles/additional-licences/licences?type=' + licenceType).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error checking if licences are available');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete all revoked tokens
   */
  deleteRevokedTokens(licenceType, success, error) {
    this.$http.delete(this.baseUrl + '/amobiles/additional-licences/revoked?type=' + licenceType).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error deleting revoked tokens');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Add a new unit
   */
  addUnit(request: AddUnitRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/units', request).then((response) => {
        resolve(true);
      }, (err) => {
        reject(err);
      });
    });

  };

  /**
   * Update an existing unit
   */
  updateUnit(unit: Unit, success, error) {
    this.$http.put(`${this.baseUrl}/units/${encodeURIComponent(unit.id)}`, {
      name: unit.name,
      code: unit.code
    } as EditUnit).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error updating an existing unit ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Delete an existing unit
   */
  deleteUnit(unitId: string, success, error) {
    this.$http.delete(`${this.baseUrl}/units/${encodeURIComponent(unitId)}`).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Error deleting existing unit ');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Update the state of an input plugin
   */
  updateInputState(inputPlugin, state, success, error) {
    this.$http.put(this.baseUrl + '/admin/input/' + encodeURIComponent(inputPlugin.id) + '/state', state).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, (response) => {
      this.$log.error('Could not update state of input plugin');
      this.handleErrorResponse(response, error);
    });
  };

  /**
   * Get the number of active input plugins
   * @param {} success
   * @param {*} error
   */
  getNumberOfActiveInputPlugins(success, error) {
    this.$http.head(this.baseUrl + '/admin/input/active').then((response) => {
      var data = {
        count: parseInt(response.headers('number-of-active-input-plugins')),
        countError: parseInt(response.headers('number-of-active-input-plugins-with-error-state')),
        countWarning: parseInt(response.headers('number-of-active-input-plugins-with-warning-state'))
      };
      this.handleSuccessResponse(data, success);
    }, (errorResponse) => {
      this.$log.error('Error getting current number of alarms');
      this.handleErrorResponse(errorResponse, error);
    })
  }

  deleteStatusForVehicles(vehicles, selectedDayRange, success, error) {
    this.$http.post(this.baseUrl + '/vehicles/status/delete?dayRange=' + selectedDayRange, vehicles).then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, errorResponse => {
      this.handleErrorResponse(errorResponse, error);
    })
  }

  getNumberOfAlarmsForAllUsers(success, error) {
    this.$http.get(this.baseUrl + '/admin/alarms').then((response) => {
      this.handleSuccessResponse(response.data, success);
    }, errorResponse => {
      this.handleErrorResponse(errorResponse, error);
    })
  }

  /**
   *
   * CALENDAR APIS
   *
   */

  createCalendar(calendarAccess: CalendarAccess): Promise<CalendarAccess> {
    return new Promise<CalendarAccess>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/eventPlanning/calendars', calendarAccess).then((response) => {
        resolve(response.data as CalendarAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }


  /**
   * Save an existing calendar
   */
  saveCalendar(calendarAccess: CalendarAccess): Promise<CalendarAccess> {
    return new Promise<CalendarAccess>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/eventPlanning/calendars/' + calendarAccess.calendar.id, calendarAccess).then((response) => {
        resolve(response.data as CalendarAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getCalendarById(calendarId: string): Promise<CalendarAccess> {
    return new Promise<CalendarAccess>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars/' + calendarId).then((response) => {
        resolve(response.data as CalendarAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  deleteCalendarById(calendarId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/eventPlanning/calendars/' + calendarId).then((response) => {
        resolve(true);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(false);
      });
    });
  }

  getCalendars(): Promise<CalendarSimple[]> {
    return new Promise<CalendarSimple[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars?simplified=true').then((response) => {
        resolve(response.data as CalendarSimple[]);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getEventsForCalendarId(calendarId: string): Promise<EventAccess[]> {
    return new Promise<EventAccess[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars/' + calendarId + '/events').then(response => {
        resolve(response.data as EventAccess[]);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });

  }

  getFeedbacksForEvent(calendarId: string, eventId: string): Promise<EventFeedbackResponse> {
    return new Promise<EventFeedbackResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars/' + calendarId + '/events/' + eventId + '/feedback').then(response => {
        resolve(response.data as EventFeedbackResponse);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });

  }

  getEventsForUserInMonth(month: number, year: number, withSurroundingMonths: boolean): Promise<EventAccess[]> {
    return new Promise<EventAccess[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/events/user/' + year + '/' + month + '?surrounding=' + withSurroundingMonths).then(response => {
        resolve(response.data as EventAccess[]);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });


  }

  getCalendarTemplate(type: ECalendarType): Promise<CalendarAccess> {
    return new Promise<CalendarAccess>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars/new?type=' + type).then(response => {
        resolve(response.data as CalendarAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getEventTemplateForCalendar(calendarId: string, eventType: EEventType, date: string): Promise<EventAccess> {
    return new Promise<EventAccess>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/eventPlanning/calendars/' + calendarId + '/events/new?type=' + eventType + '&date=' + date).then(response => {
        resolve(response.data as EventAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Create an new event
   */
  createEvent(eventAccess: EventAccess, notify: boolean): Promise<EventAccess> {
    return new Promise<EventAccess>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/eventPlanning/calendars/' + eventAccess.event.calendarId + '/events' + `?notify=${notify}`, eventAccess).then((response) => {
        resolve(response.data as EventAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Update an new event
   */
  updateEvent(eventAccess: EventAccess, notify: boolean, editUpcoming: boolean): Promise<EventAccess> {
    return new Promise<EventAccess>((resolve, reject) => {

      let url = this.baseUrl + '/eventPlanning/calendars/' + eventAccess.event.calendarId + '/events/' + eventAccess.event.id + `?notify=${notify}&upcoming=${editUpcoming}`;

      this.$http.put(url, eventAccess).then((response) => {
        resolve(response.data as EventAccess);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Delete an event
   */
  deleteEvent(event: Event, deleteSeries: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/eventPlanning/calendars/' + event.calendarId + '/events/' + event.id + '?deleteSeries=' + deleteSeries).then((response) => {
        resolve(true);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(false);
      });
    });
  }

  /**
   * Duplicate an event
   */
  duplicateEvent(event: Event): Promise<EventAccess> {
    return new Promise<EventAccess>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/eventPlanning/calendars/${event.calendarId}/events/${event.id}/duplicate`, {})
        .then((res) => {
          resolve(res.data as EventAccess);
        })
        .catch(err => {
          this.errorService.notifyWithText(err.data.message);
          reject();
        });
    });
  }


  /**
   * Delete multiple events by its state
   */
  deleteEventsByState(calendarId: string, eventState: EEventState): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/eventPlanning/calendars/' + calendarId + '/events').then((response) => {
        resolve(true);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(false);
      });
    });
  }

  /**
   * Get number of upcoming events
   */
  getNumberOfUpcomingEvents(): Promise<WidgetData> {

    return new Promise<WidgetData>((resolve, reject) => {
      this.$http.head(this.baseUrl + '/eventPlanning/events/user/today').then((response) => {
        var data = {
          count: Number.parseInt(response.headers('number-of-planned-events')),
          countError: 0,
          state: EWidgetState.READY
        } as WidgetData;
        resolve(data);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(false);
      })
    });
  }


  /**
   * Get the persons current position
   */
  getCurrentPositionForPerson(person: DataBasePersonForTracking): Promise<CurrentPosition> {
    return new Promise<CurrentPosition>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/tracking/person/' + encodeURIComponent(person.id)).then((response) => {
        resolve(response.data as CurrentPosition);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  getCustomFeedbacks(): Promise<Array<CustomFeedbackResponseResponse>> {
    return new Promise<Array<CustomFeedbackResponseResponse>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/customFeedback').then((response) => {
        resolve(response.data as Array<CustomFeedbackResponseResponse>);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getCustomFeedbacksForUser(userId: string): Promise<Array<CustomFeedbackResponseResponse>> {
    return new Promise<Array<CustomFeedbackResponseResponse>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/customFeedback?userId=${userId}`).then((response) => {
        resolve(response.data as Array<CustomFeedbackResponseResponse>);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  createCustomFeedback(data: CreateCustomFeedbackResponseRequest): Promise<CustomFeedbackResponseResponse> {
    return new Promise<CustomFeedbackResponseResponse>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/customFeedback', data).then((response) => {
        resolve(response.data as CustomFeedbackResponseResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  updateCustomFeedback(data: CustomFeedbackResponseResponse): Promise<CustomFeedbackResponseResponse> {
    return new Promise<CustomFeedbackResponseResponse>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/customFeedback/' + data.id, data).then((response) => {
        resolve(response.data as CustomFeedbackResponseResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  deleteCustomFeedback(data: CustomFeedbackResponseResponse): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/customFeedback/' + data.id).then((response) => {
        resolve(response);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getCustomFeedbackEntries(data: CustomFeedbackResponseResponse): Promise<Array<CustomFeedbackEntryResponse>> {
    return new Promise<Array<CustomFeedbackEntryResponse>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/customFeedback/' + data.id + '/entries').then((response) => {
        resolve(response.data as Array<CustomFeedbackEntryResponse>);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  createCustomFeedbackEntry(data: CreateCustomFeedbackEntryRequest): Promise<CustomFeedbackEntryResponse> {
    return new Promise<CustomFeedbackEntryResponse>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/customFeedback/' + data.parentId + '/entry', data).then((response) => {
        resolve(response.data as CustomFeedbackEntryResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  updateCustomFeedbackEntry(data: CustomFeedbackEntryResponse): Promise<CustomFeedbackEntryResponse> {
    return new Promise<CustomFeedbackEntryResponse>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/customFeedback/' + data.parentId + '/entry/' + data.id, data).then((response) => {
        resolve(response.data as CustomFeedbackEntryResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  deleteCustomFeedbackEntry(data: CustomFeedbackEntryResponse): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/customFeedback/' + data.parentId + '/entry/' + data.id).then((response) => {
        resolve(response);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }


  /**
   * Load a list of all available groups for status tableau
   */
  getGroupsForTableau(forceUpdate: boolean): Promise<Array<string>> {
    return new Promise<Array<string>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/status/table/groups?forceUpdate=' + forceUpdate).then((response) => {
        resolve(response.data as string[]);
      }).catch((response) => {
        this.$log.error('Error loading groups for status tableau');
        this.$log.error(response);
        reject(response);
      });
    });
  }

  /**
   * Get a grid for a given group
   */
  getGridForGroup(group: string): Promise<StatusGrid> {
    return new Promise<StatusGrid>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/status/table/table/' + encodeURIComponent(group)).then((response) => {
        resolve(response.data as StatusGrid);
      }).catch((response) => {
        this.$log.error('Error loading grid for status tableau');
        this.$log.error(response);
        reject(response);
      });
    });
  }

  getStatusColorMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/statusColors').then(response => {
        resolve(response.data as Map<EVehicleStatus, string>);
      }).catch(err => reject(err));
    });
  }

  getStatusTextColorMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/statusTextColors').then(response => {
        resolve(response.data as Map<EVehicleStatus, string>);
      }).catch(err => reject(err));
    });
  }

  getStatusTranslationMapping(): Promise<Map<EVehicleStatus, string>> {
    return new Promise<Map<EVehicleStatus, string>>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/statusTranslations').then(response => {
        resolve(response.data as Map<EVehicleStatus, string>);
      }).catch(err => reject(err));
    });
  }

  getTacticalSigns(): Promise<TacticalSignCategory[]> {
    return new Promise<TacticalSignCategory[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/tacticalSigns').then(response => {
        resolve(response.data as TacticalSignCategory[]);
      }, error => {
        reject(error.data);
      });
    })
  }

  getVehicleIcons(): Promise<VehicleIcons[]> {
    return new Promise<VehicleIcons[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/icons').then(response => {
        resolve(response.data as VehicleIcons[]);
      }, error => {
        reject(error.data);
      });
    })
  }

  /**
   * Schedules a pending reset timer task immediately (e.g. to reset STATUS_5 immedidately)
   * @param vehicle
   */
  schedulePendingResetTimerTask(vehicle: Vehicle): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/' + vehicle.id + '/status/reset')
        .then(response => {
          resolve((response.data as QueueEntryResponse));
        }).catch(err => reject(false));
    });
  }

  addOrganisation(name: string, password: string, createOnlineService: boolean): Promise<boolean> {
    var data = {
      name: name,
      password: password,
      createOnlineService: createOnlineService
    } as CreateOrganisationRequest

    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/admin/users/add', data).then(response => {
        resolve(true);
      }, errorResponse => {
        reject(false);
        this.errorService.notifyWithText(errorResponse.data.message);
      })
    })
  }


  /**
   * Get all users for admin context
   */
  getAllUsersForAdmin(): Promise<UserAdminContext[]> {
    return new Promise<UserAdminContext[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/users')
        .then(response => resolve(response.data as UserAdminContext[]))
        .catch(err => reject(err));
    });
  }

  /**
   * Get all users for admin context
   */
  unblockUser(userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/users/unblock/'+ userId)
        .then(response => resolve())
        .catch(err => reject(err));
    });
  }

  /**
   * Reset 2FA for a given User
   */
  reset2FA(user: UserAdminContext): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let url = this.baseUrl + '/admin/users/' + user.id + '/reset2FA';
      this.$http.get(url)
        .then(() => resolve(true))
        .catch(err => reject(err));
    });
  }


  /**
   * Load a single user as admin
   */
  loadUser(userId: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/users/' + encodeURIComponent(userId))
        .then(response => resolve(response.data as User))
        .catch(err => reject(err));
    });
  }

  /**
   * Changes the users password
   */
  changePasswordForUser(userId: string, newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/users/' + encodeURIComponent(userId) + '/resetPassword?newPassword=' + encodeURIComponent(newPassword))
        .then(response => resolve())
        .catch(err => reject(err));
    });
  }


  getMeta() {
    return new Promise<MetaInformation[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/meta/list')
        .then(response => resolve(response.data as MetaInformation[]))
        .catch(err => reject(err))
    })
  }

  getMetaForMap() {
    return new Promise<MetaInformation[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/meta/map')
        .then(response => resolve(response.data as MetaInformation[]))
        .catch(err => reject(err))
    })
  }

  // ANNOUNCEMENTS

  loadAnnouncements(page: number, limit: number) {
    return new Promise<AnnouncementResponses>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/announcements?page=' + page + '&limit=' + limit)
        .then(response => resolve(response.data as AnnouncementResponses))
        .catch(err => reject(err))
    })
  }

  newAnnouncement(name: String) {
    return new Promise<Announcement>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/announcements', {name: name}).then((res) => {
          resolve(res.data as Announcement);
        },
        (errorResponse) => {
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(errorResponse);
        })
    })
  }

  saveAnnouncement(announcement: Announcement): Promise<Announcement> {
    return new Promise<Announcement>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/announcements', announcement).then((res) => {
          resolve(res.data as Announcement);
        },
        (errorResponse) => {
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(errorResponse);
        })
    })
  }

  sendAnnouncement(announcementId: string): Promise<Announcement> {
    return new Promise<Announcement>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/announcements/${announcementId}/send`).then((res) => {
        resolve(res.data as Announcement);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getAnnouncementAndCalculateReceivers(announcementId: string, notification: EAnnouncementNotification): Promise<Announcement> {
    return new Promise<Announcement>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/announcements/${announcementId}?notifications=${notification}`).then((res) => {
        resolve(res.data as Announcement);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    })
  }


  saveAndSendAnnouncement(announcement: AnnouncementRequest): Promise<Announcement> {
    return new Promise<Announcement>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/announcements/send`, announcement).then((res) => {
        resolve(res.data as Announcement);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }


  deleteAnnouncementById(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/announcements/' + id).then((response) => {
        resolve(true);
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(false);
      })
    })
  }

  getUsersForShare(): Promise<UserAdminContext[]> {
    return new Promise<UserAdminContext[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/users').then(response => {
        resolve(response.data as UserAdminContext[]);
      }).catch(error => reject(error));
    })
  }

  getUsersForPerson(personId: string): Promise<UserSearchResponse[]> {
    return new Promise<UserSearchResponse[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/addressbook/personInUsers/' + encodeURIComponent(personId)).then(response => {
        resolve(response.data as UserSearchResponse[]);
      }).catch(error => reject(error));
    });
  }

  getAllUsersPaginated(page: number, limit: number, filter: string): Promise<OrganisationPaginatedResponse> {
    return new Promise<OrganisationPaginatedResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/global/users/search/paginated?page=' + page + '&limit=' + limit + '&filter=' + filter).then(response => {
        resolve(response.data as OrganisationPaginatedResponse);
      }).catch(error => {
        reject(error);
      });
    });
  }

  updateSharedPersonForUser(request: SharePersonRequest) {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/share/person', request).then(() => {
        resolve();
      }).catch(error => reject(error));
    });
  }


  getAvailabilitySummary(): Promise<AvailabilitySummaryResponse> {
    return new Promise<AvailabilitySummaryResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/availability/summary').then(response => {
        resolve(response.data as AvailabilitySummaryResponse);
      }).catch(error => {
        reject(error);
      });
    });
  }

  /**
   * Load all AlarmObject changes for given id
   */
  loadChangesForAlarmObject(alarmObjectId: string): Promise<AlarmObjectChange[]> {
    return new Promise<AlarmObjectChange[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/objects/' + alarmObjectId + '/changes').then(response => {
        resolve(response.data as AlarmObjectChange[]);
      }).catch(error => {
        reject(error);
      });
    });
  }

  isRevisionActive(): Promise<RevisionState> {
    return new Promise<RevisionState>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/pluginRevision/state/').then(response => {
        resolve(response.data as RevisionState);
      }).catch(error => {
        reject(error);
      });
    })
  }

  getPluginsForRevisionWithStatus(): Promise<RevisionListResponse> {
    return new Promise<RevisionListResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/pluginRevision/list').then(response => {
        resolve(response.data as RevisionListResponse);
      }).catch(error => {
        reject(error);
      });
    })
  }

  removeRevisionForPlugin(pluginName: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(this.baseUrl + '/admin/pluginRevision/config/remove/' + pluginName).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    })
  }

  addRevisionForPlugin(pluginName: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/admin/pluginRevision/config/add/' + pluginName, undefined).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    })
  }

  setAllPluginsInRevision(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/pluginRevision/config/activate').then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  }

  setAllPluginsNotInRevision(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/pluginRevision/config/deactivate').then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  }

  getLogo(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/account/logo').then((response) => {
        resolve(response.data as string);
      }).catch(error => {
        reject(error);
      })
    });
  }


  /**
   * Get all swap entries for given User
   */
  getSwapProtocol(page: number, limit: number): Promise<VehicleSwapHistory> {
    return new Promise<VehicleSwapHistory>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/vehicles/swap/entries').then((response) => {
        resolve(response.data as VehicleSwapHistory);
      }).catch(error => {
        reject(error);
      })
    });
  }


  /**
   * Load a single vehicle
   * @param vehicleId The vehicles to load
   * @returns
   */
  getVehicle(vehicleId: string): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/vehicles/${vehicleId}`).then((response) => {
        resolve(response.data as Vehicle);
      }).catch(error => {
        reject(error);
      })
    });
  }


  /**
   * Load the provisioning version info for a given aPager PRO device
   * @param aPagerPro
   * @param provisioningId
   * @returns
   */
  getProvisioningVersionInfoForDevice(personID: string): Promise<ProvisioningVersionResponse> {
    personID = encodeURIComponent(personID);
    return new Promise<ProvisioningVersionResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/apager/status/provisioning?personID=${personID}`).then((response) => {
        resolve(response.data as ProvisioningVersionResponse);
      }).catch(error => {
        reject(error);
      })
    });
  }

  generateAPagerToken(person: PersonNewFieldStructure): Promise<APagerTokenForPersonResponse> {
    return new Promise<APagerTokenForPersonResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/addressbook/person/${person.personID}/token`).then(response => {
        resolve(response.data as APagerTokenForPersonResponse);
      }, error => {
        reject(error);
        this.errorService.notifyWithText(error.data.message)
      })
    })
  }

  /**
   * Load all users AAOs
   * @param page
   * @param limit
   * @returns
   */
  loadAAOs(page: number, limit: number): Promise<AAOResponsePaginated> {
    return new Promise<AAOResponsePaginated>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/aao?page=${page}&limit=${limit}`)
        .then(response => resolve(response.data as AAOResponsePaginated))
        .catch(err => reject(err))
    });
  }

  /**
   * Add a new AAO
   * @param name
   * @returns
   */
  addAAO(name: String): Promise<AAO> {
    return new Promise<AAO>((resolve, reject) => {
      this.$http.post(this.baseUrl + '/aao', {name: name}).then((res) => {
        resolve(res.data as AAO);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }


  /**
   * Load all entries for given AAO
   * @returns
   */
  loadAAOEntries(id: string): Promise<AAOEntryResponse> {
    return new Promise<AAOEntryResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/aao/${encodeURIComponent(id)}/entries`)
        .then(response => resolve(response.data as AAOEntryResponse))
        .catch(err => reject(err))
    });
  }

  /**
   * Add a new AAO
   * @param parentId
   * @returns
   */
  addAAOEntry(parentId: string): Promise<AAOEntryResponseElement> {
    return new Promise<AAOEntryResponseElement>((resolve, reject) => {
      this.$http.post(this.baseUrl + `/aao/${encodeURIComponent(parentId)}/entries`, {parentId: parentId}).then((res) => {
        resolve(res.data as AAOEntryResponseElement);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Save an existing saveAAOEntry
   * @returns
   */
  saveAAOEntry(aao: AAO, entry: AAOEntryResponseElement): Promise<AAOEntryResponseElement> {
    return new Promise<AAOEntryResponseElement>((resolve, reject) => {
      this.$http.put(this.baseUrl + `/aao/${encodeURIComponent(aao.id)}/entries/${encodeURIComponent(entry.id)}`, entry).then((res) => {
        resolve(res.data as AAOEntryResponseElement);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Delete an existing AAO
   * @param id
   * @returns
   */
  deleteAAO(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + `/aao/${encodeURIComponent(id)}`).then((res) => {
        resolve(true);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Delete an existing AAO entry
   * @param aaoId
   * @param entryId
   * @returns
   */
  deleteAAOEntry(aaoId: string, entryId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(this.baseUrl + `/aao/${encodeURIComponent(aaoId)}/entries/${encodeURIComponent(entryId)}`).then((res) => {
        resolve(true);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Save an existing AAO
   * @param id
   * @returns
   */
  saveAAO(aao: AAO): Promise<AAO> {
    return new Promise<AAO>((resolve, reject) => {
      this.$http.put(this.baseUrl + `/aao/${encodeURIComponent(aao.id)}`, aao).then((res) => {
        resolve(res.data as AAO);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Duplicate an existing AAO
   * @param aaoId
   * @returns
   */
  duplicateAAO(aaoId: string): Promise<AAO> {
    return new Promise<AAO>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/aao/${encodeURIComponent(aaoId)}/copy`).then((res) => {
        resolve(res.data as AAO);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Save all AAO assignments for given entry
   * @param aao
   * @param entry
   * @param orders
   * @returns
   */
  saveAAOAssignments(aao: AAO, entry: AAOEntryResponseElement, orders: AAOVehicleAssignment[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const data = orders.map(value => {
        return {
          vehicleId: value.vehicle.id,
          order: value.order,
          subOrder: value.subOrder
        };
      });
      this.$http.put(this.baseUrl + `/aao/${encodeURIComponent(aao.id)}/entries/${encodeURIComponent(entry.id)}/assignments`, data).then((res) => {
        resolve(true);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }

  /**
   * Get all AAO assignments for given entry
   * @param aao
   * @param entry
   * @returns
   */
  getAAOAssignments(aao: AAO, entry: AAOEntryResponseElement): Promise<AAOVehicleAssignment[]> {
    return new Promise<AAOVehicleAssignment[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + `/aao/${encodeURIComponent(aao.id)}/entries/${encodeURIComponent(entry.id)}/assignments`).then((res) => {
        resolve(res.data as AAOVehicleAssignment[]);
      }, (errorResponse) => {
        if (errorResponse.data) {
          this.errorService.notifyWithText(errorResponse.data.error);
        } else {
          this.errorService.notify(this.errorService.ERROR_IDS.UNKNOWN_ERROR);
        }
        reject(errorResponse);
      })
    });
  }


  /**
   * Load the admin privacy settings
   * @returns
   */
  getAdminPrivacySettings(): Promise<PrivacySettingsResponse> {
    return new Promise<PrivacySettingsResponse>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/settings/privacy').then((res) => {
        resolve(res.data as PrivacySettingsResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }

  getAdminPrivacyMappings(): Promise<PrivacyMappingResponse[]> {
    return new Promise<PrivacyMappingResponse[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/settings/privacy/mapping').then((res) => {
        resolve(res.data as PrivacyMappingResponse[]);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }
  /**
   * Load the admin privacy settings
   * @returns
   */
  getAgeVerificationSetting(): Promise<AgeVerificationSetting> {
    return new Promise<AgeVerificationSetting>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/admin/settings/privacy/ageVerification').then((res) => {
        resolve(res.data as AgeVerificationSetting);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }

  /**
   * Update the admin privacy settings
   */
  saveAdminPrivacySettings(settings: PrivacySettingsResponse): Promise<PrivacySettingsResponse> {
    return new Promise<PrivacySettingsResponse>((resolve, reject) => {
      this.$http.put(this.baseUrl + '/admin/settings/privacy', settings).then((res) => {
        resolve(res.data as PrivacySettingsResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  getTraceLogForUnit(unit: Unit): Promise<LogObjectTransferable[]> {
    return new Promise<LogObjectTransferable[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/units/log/' + unit.id).then((res) => {
        resolve(res.data as LogObjectTransferable[])
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  getEntryForSelectedAlarmTraceAndPlugin(plugin, alarmId: string): Promise<LogObjectEntryTransferable[]> {
    return new Promise<LogObjectEntryTransferable[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/units/log/entries?alarm=' + encodeURIComponent(alarmId) + '&plugin=' + encodeURIComponent(plugin.uuid)).then(res => {
        resolve(res.data as LogObjectEntryTransferable[]);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }


  /**
   * Load the admin privacy settings
   * @returns
   */
  getRoles(): Promise<Role[]> {
    return new Promise<Role[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/roles`).then((res) => {
        resolve(res.data as Role[]);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }

  /**
   * Save existing role
   */
  saveRole(role: RoleUpdateRequest): Promise<Role> {
    return new Promise<Role>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/roles`, role).then((res) => {
        resolve(res.data as Role);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }

  /**
   * Duplicate existing role
   */
  duplicateRole(role: Role): Promise<Role> {
    return new Promise<Role>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/roles/${role.id}/clone`).then((res) => {
        resolve(res.data as Role);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }

  /**
   * Add a new role
   */
  addRole(name: string): Promise<Role> {
    return new Promise<Role>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/roles`, {
        name: name
      } as CreateRoleRequest).then((res) => {
        resolve(res.data as Role);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      })
    });
  }


  /**
   * Assign given role to user
   * @param personId
   * @param roleId
   * @returns
   */
  assignRole(personId: string, roles?: RoleSimpleSelection[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      const roleIds: string[] = [];

      roles.filter(role => role.selected).forEach(role => roleIds.push(role.id))

      if (roleIds.length > 0) {
        this.$http.put(`${this.baseUrl}/roles/person`, {
          personId: personId,
          roleIds
        } as AssignRoleRequest).then((res) => {
          resolve(true);
        }, (errorResponse) => {
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(errorResponse);
        });
      } else {
        this.$http.delete(`${this.baseUrl}/roles/person/${encodeURIComponent(personId)}`).then((res) => {
          resolve(true);
        }, (errorResponse) => {
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(errorResponse);
        });
      }


    });
  }

  /**
   * Delete an existing role
   */
  deleteRole(roleId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/roles/${encodeURIComponent(roleId)}`).then((res) => {
        resolve(true);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  /**
   * Load all roles for a given user
   * @returns
   */
  loadRoles(): Promise<Role[]> {
    return new Promise<Role[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/roles`).then((res) => {
        resolve(res.data as Role[]);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  /**
   * Load all roles (simple) for a given user
   * @returns
   */
  loadRolesSimple(): Promise<RoleSimpleResponse[]> {
    return new Promise<RoleSimpleResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/roles/simple`).then((res) => {
        resolve(res.data as RoleSimpleResponse[]);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  loadAvailableSplitters(): Promise<Splitter[]> {
    return new Promise<Splitter[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/units/splitters`).then((res) => {
        resolve(res.data as Splitter[]);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    })
  }

  loadDuplicateSettings(): Promise<DuplicateGlobalSettings> {
    return new Promise<DuplicateGlobalSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/duplicate`).then((res) => {
        resolve(res.data as DuplicateGlobalSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  saveDuplicateSettings(settings: DuplicateGlobalSettings): Promise<DuplicateGlobalSettings> {
    return new Promise<DuplicateGlobalSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/duplicate`, settings).then((res) => {
        resolve(res.data as DuplicateGlobalSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  /**
   * Load all miscellaneous settings
   * @returns
   */
  loadMiscSettings(): Promise<MiscSettings> {
    return new Promise<MiscSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/misc`).then((res) => {
        resolve(res.data as MiscSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  /**
   * Save all miscellaneous settings
   * @returns
   */
  saveMiscSettings(settings: MiscSettings): Promise<MiscSettings> {
    return new Promise<MiscSettings>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/misc`, settings).then((res) => {
        resolve(res.data as MiscSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  getStatusSettings(): Promise<StatusSettings> {
    return new Promise<StatusSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/statusSettings`).then((res) => {
        resolve(res.data as StatusSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  saveStatusSettings(settings: StatusSettings): Promise<StatusSettings> {
    return new Promise<StatusSettings>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/statusSettings`, settings).then((res) => {
        resolve(res.data as StatusSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }


  saveCustomMapLayer(layer: CustomMapLayer): Promise<CustomMapLayer> {
    return new Promise<CustomMapLayer>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/mapLayer`, layer).then((res) => {
        resolve(res.data as CustomMapLayer);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  loadIOEspaConfigString(id: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/external/ioespa/${id}/config`).then((res) => {
        resolve((res.data as GeneralResponse).message);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
      });
    });
  }

  /**
   * Load a single person
   * @param personId
   * @returns
   */
  loadSinglePerson(personId: string): Promise<Person> {
    return new Promise<Person>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/addressbook/person/${encodeURIComponent(personId)}`).then(res => {
        resolve(res.data as Person);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  /**
   * Load all vehicle assignments
   */
  loadAllVehiclesWithAlarm(sortOrder: EVehicleSortOrder, reverseOrder: boolean): Promise<VehicleAssignedAlarm[]> {
    return new Promise<VehicleAssignedAlarm[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/alarm?sort=${sortOrder}&reverse=${reverseOrder}`).then(res => {
        resolve(res.data as VehicleAssignedAlarm[]);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  /**
   * Load all vehicle assignments
   */
  loadAllVehiclesWithAlarmCombined(): Promise<VehicleAssignedAlarmCombined[]> {
    return new Promise<VehicleAssignedAlarmCombined[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/alarm/combined`).then(res => {
        resolve(res.data as VehicleAssignedAlarmCombined[]);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  updateFunctionsGroupsForPerson(data: ChangeFunctionGroupForPerson): Promise<Person> {
    return new Promise<Person>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/addressbook/person/${encodeURIComponent(data.personID)}/functionsgroups`, data).then(res => {
        resolve(res.data as Person);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  /**
   * Load a single vehicle assignment
   */
  loadVehicleWithAlarm(vehicleId: string): Promise<VehicleAssignedAlarm> {
    return new Promise<VehicleAssignedAlarm>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/alarm/${encodeURIComponent(vehicleId)}`).then(res => {
        resolve(res.data as VehicleAssignedAlarm);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }


  /**
   * Load all keywords paginated
   * @param page
   * @param limit
   * @returns
   */
  loadKeywordsPaginated(page: number, limit: number): Promise<PaginatedKeywordResponse> {
    return new Promise<PaginatedKeywordResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/keywords/paginated?page=${page}&limit=${limit}`).then(res => {
        resolve(res.data as PaginatedKeywordResponse);
      });
    });
  }

  /**
   * Loads the webserver settings
   */
  loadWebserverSettings(): Promise<WebserverSettings> {
    return new Promise<WebserverSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/webserver`).then(res => {
        resolve(res.data as WebserverSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Saves the webserver settings
   */
  saveWebserverSettings(settings: WebserverSettings): Promise<WebserverSettings> {
    return new Promise<WebserverSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/webserver`, settings).then(res => {
        resolve(res.data as WebserverSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Loads the master-/slave settings
   */
  loadMasterSlaveSettings(): Promise<MasterSlaveSettings> {
    return new Promise<MasterSlaveSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/masterslave`).then(res => {
        resolve(res.data as MasterSlaveSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Saves the master-/slave settings
   */
  saveMasterSlaveSettings(settings: MasterSlaveSettings): Promise<MasterSlaveSettings> {
    return new Promise<MasterSlaveSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/masterslave`, settings).then(res => {
        resolve(res.data as MasterSlaveSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Loads the mailaccount settings
   */
  loadMailAccountSettings(): Promise<MailAccountSettings> {
    return new Promise<MailAccountSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/mailaccount`).then(res => {
        resolve(res.data as MailAccountSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Saves the mailaccount settings
   */
  saveMailAccountSettings(settings: MailAccountSettings): Promise<MailAccountSettings> {
    return new Promise<MailAccountSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/mailaccount`, settings).then(res => {
        resolve(res.data as MailAccountSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }


  loadKeywordSettings(): Promise<KeywordSettings> {
    return new Promise<KeywordSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/keyword`).then(res => {
        resolve(res.data as KeywordSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }


  saveKeywordSettings(settings: KeywordSettings): Promise<KeywordSettings> {
    return new Promise<KeywordSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/keyword`, settings).then(res => {
        resolve(res.data as KeywordSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Loads the mailaccount settings
   */
  loadOutputPluginSettings(): Promise<OutputPluginSettings> {
    return new Promise<OutputPluginSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/outputPlugins`).then(res => {
        resolve(res.data as OutputPluginSettings);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  setOutputPluginStatus(plugin: DeactivatedPluginListEntry, deactivate: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!deactivate) {
        this.$http.get(`${this.baseUrl}/admin/outputPlugins/${plugin.className}/activate`).then(res => {
          resolve();
        }, errorResponse => {
          reject(errorResponse);
        });
      } else {
        this.$http.get(`${this.baseUrl}/admin/outputPlugins/${plugin.className}/deactivate`).then(res => {
          resolve();
        }, errorResponse => {
          reject(errorResponse);
        });
      }
    });
  }

  loadReplacementSettings(): Promise<ReplacementSettings> {
    return new Promise<ReplacementSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/replacements`).then(res => {
        resolve(res.data as ReplacementSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }


  saveReplacementSettings(settings: ReplacementSettings): Promise<ReplacementSettings> {
    return new Promise<ReplacementSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/replacements`, settings).then(res => {
        resolve(res.data as ReplacementSettings);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  /**
   * Load all Online Services as Admin
   */
  loadAdminOnlineServices(): Promise<OnlineServiceMapping[]> {
    return new Promise<OnlineServiceMapping[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/onlineservices`).then(res => {
        resolve(res.data as OnlineServiceMapping[]);
      }, errorResponse => {
        reject(errorResponse);
      });
    });

  }

  loadAllFeedbackUnitGroups(): Promise<FeedbackUnitGroupSimple[]> {
    return new Promise<FeedbackUnitGroupSimple[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups`).then((response) => {
        resolve(response.data as FeedbackUnitGroupSimple[]);
      }, (errorResponse) => {
        reject(errorResponse);
      })
    })

  }

  getUnitsForFeedbackGroup(feedbackUnitGroup: string): Promise<FeedbackGroupsUnits> {
    return new Promise<FeedbackGroupsUnits>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/${feedbackUnitGroup}`).then((response) => {
        resolve(response.data as FeedbackGroupsUnits);
      }, (errorResponse) => {
        reject(errorResponse);
        this.errorService.notifyWithText(errorResponse.data.message);
      })
    })
  }

  deleteFeedbackUnitGroup(feedbackUnitGroup: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/${feedbackUnitGroup}`).then((response) => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  createFeedbackUnitGroup(feedbackUnitGroupSimple: FeedbackUnitGroupSimple): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/`, feedbackUnitGroupSimple).then((response) => {
        resolve(true);
      }, errorResponse => {
        reject(false);
      });
    });
  }

  updateFeedbackUnitGroupWithoutUnits(feedbackUnitGroupSimple: FeedbackUnitGroupSimple): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/`, feedbackUnitGroupSimple).then((response) => {
        resolve(true);
      }, errorResponse => {
        reject(false);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  updateFeedbackUnitGroup(feedbackUnitGroup: FeedbackGroupsUnits): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/units`, feedbackUnitGroup).then((response) => {
        resolve(true);
      }, errorResponse => {
        reject(false);
        this.errorService.notifyWithText(errorResponse.data.message);
      });
    });
  }

  getAllUnitsWithFeedbackMatching(localSearchFilterAllUnits: string): Promise<Unit[]> {
    return new Promise<Unit[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/onlineservices/unitFeedbackGroups/units/all?filter=${encodeURIComponent(localSearchFilterAllUnits)}`).then((response) => {
        resolve(response.data as Unit[]);
      }, error => {
        reject(error)
      })
    })
  }

  updateOnlineServiceMapping(userId: string, osId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/onlineservices`, {
        osId: osId,
        userId: userId
      } as UpdateOnlineServiceMapping).then(res => {
        resolve(true);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  createOnlineService(userId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/onlineservices`, {
        userId: userId
      }).then(res => {
        resolve(true);
      }, errorResponse => {
        reject(errorResponse);
      })
    });
  }

  switchRelais(relaisId: string, state: ERelaisState): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/relais/${relaisId}/switch?state=${state}`, null).then(res => {
        resolve();
      }, errorResponse => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse)
      })
    });
  }

  loadOutageSettings(): Promise<OutageSettings> {
    return new Promise<OutageSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/outage`).then(res => {
        resolve(res.data as OutageSettings);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  updateOutageSettings(outageSettings: OutageSettings): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/outage`, outageSettings).then(res => {
        resolve(true);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  loadAssignedAlarmObjectsForUser(userId: string): Promise<SimpleAlarmObject[]> {
    return new Promise<SimpleAlarmObject[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/alarmobjects/${userId}`).then(res => {
        resolve(res.data as SimpleAlarmObject[]);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  addAlarmObjectsAssignment(userId: string, alarmObject?: SimpleAlarmObject): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/alarmobjects`, {
        userId: userId,
        alarmObjectId: alarmObject ? alarmObject.id : undefined,
        addAllObjects: alarmObject === undefined
      } as AssignAlarmObject).then(res => {
        resolve(true);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }

  alarmObjectsAssignAllUsers(alarmObjectId: string): Promise<GeneralResponse> {
    return new Promise<GeneralResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/settings/alarmobjects/assignAllUsers/${alarmObjectId}`, null).then(res => {
        resolve(res.data as GeneralResponse);
      }, errorResponse => {
        reject(errorResponse);
      });
    });
  }


  removeAlarmObjectsAssignment(userId: string, alarmObject: SimpleAlarmObject): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/settings/alarmobjects?userId=${userId}&alarmObjectId=${alarmObject.id}`)
        .then(res => {
          resolve(true);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  searchForAlarmObjectForAssignments(currentPage: number, limit: number, searchFilter: string, parent: string): Promise<AlarmObjectSearchResult> {
    return new Promise<AlarmObjectSearchResult>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/alarmobjects/search?parent=${encodeURIComponent(parent)}&currentPage=${currentPage}&limit=${limit}&searchFilter=${encodeURIComponent(searchFilter)}`)
        .then(res => {
          resolve(res.data as AlarmObjectSearchResult);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Start FIDO2 registration process
   */
  fidoRegister(): Promise<webauthnJson.CredentialCreationOptionsJSON> {
    return new Promise<webauthnJson.CredentialCreationOptionsJSON>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/fido/register`)
        .then(res => {
          resolve(res.data as webauthnJson.CredentialCreationOptionsJSON);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Finish the FIDO2 registration
   */
  fidoFinishRegistration(data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/fido/finish`, data)
        .then(res => {
          resolve(res.data as any);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Start FIDO2 authentication process
   */
  fidoStartAuthentication(username: string): Promise<webauthnJson.CredentialRequestOptionsJSON> {
    return new Promise<webauthnJson.CredentialRequestOptionsJSON>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/login/fido?username=${encodeURIComponent(username)}`)
        .then(res => {
          resolve(res.data as webauthnJson.CredentialRequestOptionsJSON);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Finish fido authentication
   * @param username
   * @param data
   * @returns
   */
  fidoFinishAuthentication(username: string, data): Promise<AccountResponse> {
    return new Promise<AccountResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/login/fido?username=${encodeURIComponent(username)}`, data)
        .then(res => {
          const accountResponse = res.data as AccountResponse;
          this.token = accountResponse.token;
          this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
          resolve(accountResponse);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Get all FIDO2 registrations
   */
  getAllFidoRegistraions(): Promise<FidoRegistration[]> {
    return new Promise<FidoRegistration[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/fido/registrations`)
        .then(res => {

          resolve((res.data as any[]).map(e => {
            return {
              alias: e.alias,
              id: e.id,
              lastLogin: new Date(e.lastLogin)
            } as FidoRegistration;
          }));
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Update an existing FIDO2 registration
   */
  updateFidoRegistraion(data: FidoRegistration): Promise<FidoRegistration> {
    return new Promise<FidoRegistration>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/fido/registrations/${data.id}`, data)
        .then(res => {
          resolve(res.data as FidoRegistration);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  /**
   * Delete an existing FIDO2 registration
   */
  deleteFidoRegistraion(data: FidoRegistration): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/fido/registrations/${data.id}`)
        .then(res => {
          resolve(true);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  createInputPlugin(inputPlugin: SimpleInputPluginInstance, name: string): Promise<SimpleInputPluginInstance> {
    return new Promise<SimpleInputPluginInstance>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/input`, {
        simpleInputClassName: inputPlugin.simpleInputClassName,
        name: name
      } as CreateInputPluginInstance)
        .then(res => {
          resolve(res.data as SimpleInputPluginInstance);
        }, errorResponse => {
          reject(errorResponse);
        });
    });
  }

  restartFE2() {
    return this.$http.get(`${this.baseUrl}/admin/restart`);
  }

  loadAllIOBatteries(): Promise<IOBattery[]> {
    return new Promise<IOBattery[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/iobattery`)
        .then(result => {
          resolve(result.data as IOBattery[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  loadIOBatteriesForUser(user: User): Promise<IOBattery[]> {
    return new Promise<IOBattery[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/iobattery/assignment/${user.id}`)
        .then(result => {
          resolve(result.data as IOBattery[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  addAccessToIOBattery(user: User, ioBattery: IOBattery): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/iobattery/assignment?userId=${user.id}&ioBatteryId=${ioBattery.dataStoreId}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  removeAccessToIOBattery(user: User, ioBattery: IOBattery) {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/iobattery/assignment?userId=${user.id}&ioBatteryId=${ioBattery.dataStoreId}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    })
  }

  getIOBatteriesAsUser(): Promise<IOBattery[]> {
    return new Promise<IOBattery[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/iobattery`)
        .then(result => {
          resolve(result.data as IOBattery[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  loadBatteryMeasurements(deviceId: string, startTime: Date, endTime: Date): Promise<IOBatteryMeasurement[]> {
    return new Promise<IOBatteryMeasurement[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/iobattery/${deviceId}/measurements?from=${startTime.getTime()}&to=${endTime.getTime()}`)
        .then(result => {
          resolve(result.data as IOBatteryMeasurement[])
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  getIOPrintJobsInfo(id: string, page: number, limit: number) {
    return new Promise<IOprintJobInfoResponsePage>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/ioprint/${id}/printJobs?page=${page}&limit=${limit}`)
        .then(result => {
          resolve(result.data as IOprintJobInfoResponsePage)
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  addAccessToIOprint(user: User, ioPrint: IOprint): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/ioprint/assignment?userId=${user.id}&ioPrintId=${ioPrint.id}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  removeAccessToIOprint(user: User, ioPrint: IOprint) {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/ioprint/assignment?userId=${user.id}&ioPrintId=${ioPrint.id}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    })
  }

  getIOprintsAsUser(): Promise<IOprint[]> {
    return new Promise<IOprint[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/ioprint`)
        .then(result => {
          resolve(result.data as IOprint[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  loadAllIOprints(): Promise<IOprint[]> {
    return new Promise<IOprint[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/ioprint`)
        .then(result => {
          resolve(result.data as IOprint[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  loadIOprintsForUser(user: User): Promise<IOprint[]> {
    return new Promise<IOprint[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/ioprint/assignment/${user.id}`)
        .then(result => {
          resolve(result.data as IOprint[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  addAccessForOrganization(org: UserContext): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/users/displayLocationForUser?displayFor=${org.id}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  removeAccessForOrganization(org: UserContext) {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/users/hideLocationForUser?hideFor=${org.id}`, {})
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    })
  }

  searchAllOrganizations(currentPage: number, limit: number, searchFilter: string): Promise<UserContextSearchResult> {
    return new Promise<UserContextSearchResult>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/users/searchAllOtherUsers?currentPage=${currentPage}&limit=${limit}&searchFilter=${encodeURIComponent(searchFilter)}`)
        .then(result => {
          resolve(result.data as UserContextSearchResult)
        })
        .catch(error => {
          reject(error);
        });
    });
  }


  loadOrganizationsWithAccess(): Promise<UserContext[]> {
    return new Promise<UserContext[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/users/getAllUserIsLocationDisplayedFor`)
        .then(result => {
          resolve(result.data as UserContext[])
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  loadOrganisationLocations(): Promise<UserLocationResponse[]> {
    return new Promise<UserLocationResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/users/getAllUsersLocationDisplayedToUser`)
        .then(res => resolve(res.data as UserLocationResponse[]))
        .catch(err => reject(err));
    })
  }


  deleteParkingPosition(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/parking/${id}`)
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    });
  }

  updateParkingPositionGates(id: string, updateRequest: ParkingPositionUpdateRequest): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/parking/${id}`, updateRequest)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }

  addParkingPosition(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/parking`, {})
        .then(() => resolve())
        .catch(error => reject(error))
    });
  }

  loadParkingPositionsAsPromise(): Promise<ListParkingPosition[]> {
    return new Promise<ListParkingPosition[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/building/parkingPositions`).then((response) => {
        resolve(response.data as ListParkingPosition[])
      })
        .catch(err => reject(err));
    })
  }

  loadParkingPositionsForUser(user: User): Promise<ListParkingPosition[]> {
    return new Promise<ListParkingPosition[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/parking/user/${user.id}`)
        .then((response) => {
          resolve(response.data as ListParkingPosition[])
        })
        .catch(err => reject(err));
    })
  }

  addAccessToParkingPosition(user: User, parkingPosition: ListParkingPosition): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/parking/${parkingPosition.id}/access`, {userId: user.id})
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  removeAccessToParkingPosition(user: User, parkingPosition: ListParkingPosition): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/parking/${parkingPosition.id}/access/${user.id}`)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  loadAlarmSplitters(): Promise<AlarmSplitterConfig> {
    return new Promise<AlarmSplitterConfig>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/splitter`)
        .then(res => resolve(res.data as AlarmSplitterConfig))
        .catch(err => reject(err));
    });
  }

  saveAlarmSplitters(config: AlarmSplitterConfig): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/splitter`, config)
        .then(res => resolve())
        .catch(err => reject(err));
    });
  }

  loadAllUnits(): Promise<SimpleUnit[]> {
    return new Promise<SimpleUnit[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/units`)
        .then(res => resolve(res.data as SimpleUnit[]))
        .catch(err => reject(err));
    });
  }

  refreshLicence(): Promise<Server> {
    return new Promise<Server>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/licence/refresh`)
        .then(res => resolve(res.data as Server))
        .catch(err => reject(err));
    });
  }


  loadLicenceDistribution(licence: AdjustLicenceType): Promise<LicenceDistribution> {
    return new Promise<LicenceDistribution>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/licence/${licence}`)
        .then(res => resolve(res.data as LicenceDistribution))
        .catch(err => reject(err));
    })
  }

  loadAddressSettings(): Promise<AddressSettings> {
    return new Promise<AddressSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/address`)
        .then(res => resolve(res.data as AddressSettings))
        .catch(err => reject(err));
    });
  }

  loadAddressObjects(page: number, limit: number): Promise<AdminAlarmObjectsResponse> {
    return new Promise<AdminAlarmObjectsResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/address/objects?page=${page}&limit=${limit}`)
        .then(res => resolve(res.data as AdminAlarmObjectsResponse))
        .catch(err => reject(err));
    });
  }

  loadAddressStreets(page: number, limit: number): Promise<AdminStreetsResponse> {
    return new Promise<AdminStreetsResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/address/streets?page=${page}&limit=${limit}`)
        .then(res => resolve(res.data as AdminStreetsResponse))
        .catch(err => reject(err));
    });
  }

  saveAddressSettings(settings: AddressSettings): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/address`, settings)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  loadComingHomeSettings(): Promise<ComingHomeSettings> {
    return new Promise<ComingHomeSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/cominghome`)
        .then(res => resolve(res.data as ComingHomeSettings))
        .catch(err => reject(err));
    });
  }

  saveHomeComingSettings(settings: ComingHomeSettings): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/settings/cominghome`, settings)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  loadBlockedAlarms(page: number, limit: number, searchParam: string, blockedReasonTypes: string): Promise<BlockedAlarmsPaginated> {
    var queryParams = `?page=${page}&limit=${limit}`;
    if (searchParam && searchParam !== '') {
      queryParams += `&unitName=${searchParam}`;
    }
    if (blockedReasonTypes && blockedReasonTypes !== '') {
      queryParams += `&blockedReasonType=${blockedReasonTypes}`
    }

    return new Promise<BlockedAlarmsPaginated>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/alarm/blockedAlarms/paginated${queryParams}`).then((response) => {
        resolve(response.data as BlockedAlarmsPaginated);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error);
      });
    });
  }

  /**
   * Get all units for a given User
   * @param userId
   * @returns
   */
  loadUnitsForUser(userId: string): Promise<Unit[]> {
    return new Promise<Unit[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/units/${userId}`)
        .then(res => resolve(res.data as Unit[]))
        .catch(err => reject(err));
    });
  }

  addUnitForUser(userId: string, unitRequest: AdminAddUnitRequest): Promise<Unit[]> {
    return new Promise<Unit[]>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/units/${userId}`, unitRequest)
        .then(res => resolve(res.data as Unit[]))
        .catch(err => reject(err));
    });
  }

  getAllUsersWithDefaultUnits(): Promise<UnitFilterEntry[]> {
    return new Promise<UnitFilterEntry[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/units/users/default`)
        .then(res => resolve(res.data as UnitFilterEntry[]))
        .catch(err => reject(err));
    });
  }


  loadAllGates(): Promise<Gate[]> {
    return new Promise<Gate[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/gates`)
        .then(res => resolve(res.data as Gate[]))
        .catch(error => reject(error));
    });
  }

  addGate(): Promise<Gate> {
    return new Promise<Gate>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/gates`, {})
        .then(res => resolve(res.data as Gate))
        .catch(err => reject(err));
    });
  }

  deleteGate(gate: Gate): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/gates/${gate.id}`)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  updateGate(gateId: string, request: GateUpdateRequest) {
    return new Promise<Gate>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/gates/${gateId}`, request)
        .then(res => resolve(res.data as Gate))
        .catch(err => reject(err));
    })
  }

  getRelaisNameResponses(): Promise<RelaisNameResponse[]> {
    return new Promise<RelaisNameResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/gates/relais`)
        .then(res => resolve(res.data as RelaisNameResponse[]))
        .catch(err => reject(err));
    });
  }

  getBuildingControllers(): Promise<SimpleBuildingControllerResponse[]> {
    return new Promise<SimpleBuildingControllerResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/controllers`).then((response) => {
        resolve(response.data as SimpleBuildingControllerResponse[])
      })
        .catch(err => reject(err));
    });
  }

  getBuildingControllerTypes(): Promise<BuildingControllerTypesResponse[]> {
    return new Promise<BuildingControllerTypesResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/controllers/types`).then((response) => {
        resolve(response.data as BuildingControllerTypesResponse[])
      })
        .catch(err => reject(err));
    });
  }

  deleteBuildingController(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/controllers/${id}`).then((response) => {
        resolve(true)
      })
        .catch(err => reject(err));
    });
  }

  addBuildingController(name: string, type: string): Promise<BuildingControllerResponse> {
    return new Promise<BuildingControllerResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/admin/controllers/`, {
        name: name,
        type: type
      } as CreateBuildingControllerRequest).then((response) => {
        resolve(response.data as BuildingControllerResponse)
      })
        .catch(err => reject(err));
    });
  }

  saveBuildingController(controller: BuildingControllerResponse): Promise<BuildingControllerResponse> {
    return new Promise<BuildingControllerResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/controllers/${controller.id}`, controller).then((response) => {
        resolve(response.data as BuildingControllerResponse)
      })
        .catch(err => reject(err));
    });
  }

  /**
   * Reload all IOsatellites
   */
  refreshIOsattellites(): Promise<SimpleBuildingControllerResponse[]> {
    return new Promise<SimpleBuildingControllerResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/controllers/refresh`).then((response) => {
        resolve(response.data as SimpleBuildingControllerResponse[])
      }).catch(err => reject(err));
    });
  }

  getBuildingControllerById(id: string): Promise<BuildingControllerResponse> {
    return new Promise<BuildingControllerResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/controllers/${id}`).then((response) => {
        resolve(response.data as BuildingControllerResponse)
      }).catch(err => reject(err));
    });
  }

  getBuildingControllersForUser(userId: string): Promise<SimpleBuildingControllerResponse[]> {
    return new Promise<SimpleBuildingControllerResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/controllers/user/${userId}`)
        .then(response => resolve(response.data as SimpleBuildingControllerResponse[]))
        .catch(err => reject(err));
    });
  }

  addBuildingControllerAccessForUser(controllerId: string, userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/controllers/${controllerId}/access`, {userId})
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  removeAccessToBuildingController(controllerId: string, userId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/admin/controllers/${controllerId}/access/${userId}`)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  loadLeaderboard(start: Date, end: Date, sort: LeaderboardSortBy, order: LeaderboardSortOrder): Promise<LeaderboardResultEntry[]> {
    return new Promise<LeaderboardResultEntry[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/leaderboard?start=${start.getTime()}&end=${end.getTime()}&ordering=${order}&sort=${sort}`)
        .then((result) => resolve(result.data as LeaderboardResultEntry[]))
        .catch(err => reject(err))
    });
  }

  getBadgesForPerson(personId: string): Promise<GamificationBadgeResult[]> {
    return new Promise<GamificationBadgeResult[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/badges/${encodeURIComponent(personId)}`)
        .then((result) => resolve(result.data as any[]))
        .catch(err => reject(err))
    });
  }

  getGoogleLink(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/email/google/link?email=${encodeURIComponent(email)}`)
        .then((result) => {
          resolve(result.data as string)
        })
        .catch(err => reject(err))
    });
  }

  getAzureLink(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/email/azure/link?email=${encodeURIComponent(email)}`)
        .then((result) => {
          resolve(result.data as string)
        })
        .catch(err => reject(err))
    });
  }

  updateTokenType(email: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/email/tokenType?email=${encodeURIComponent(email)}`)
        .then((result) => {
          resolve(result.data as string)
        })
        .catch(err => reject(err))
    });
  }

  getAllEmergencies(page: number, size: number, filter: string, ordering: string, dateFilter: number, onlyOwnMissions: boolean): Promise<Slice<EmergencyResponse>> {
    return new Promise<Slice<EmergencyResponse>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/emergency/all?page=${page}&limit=${size}&filter=${filter}&ordering=${ordering}&startDate=${dateFilter}&onlyMyMissions=${onlyOwnMissions}`)
        .then(res => resolve(res.data as Slice<EmergencyResponse>))
        .catch(err => reject(err));
    })
  }

  getEmergencyByExternalId(externalId: string, userId?: string): Promise<Emergency> {
    let query = "";
    if (userId) query = `?userId=${encodeURIComponent(userId)}`;
    return new Promise<Emergency>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/emergency/${encodeURIComponent(externalId)}${query}`)
        .then(res => resolve(res.data as Emergency))
        .catch(err => reject(err));
    });
  }

  getMissionReports(externalId: string, page: number, limit: number, userId?: string): Promise<MissionReportSlice> {
    let url = `${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/paged?page=${page}&limit=${limit}`;
    if (userId) {
      url = `${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/paged?page=${page}&limit=${limit}&userId=${userId}`
    }
    return new Promise<MissionReportSlice>((resolve, reject) => {
      this.$http.get(url).then(response => {
        resolve(response.data as MissionReportSlice);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.message);
      })
    })
  }

  loadReport(externalId: string, reportId: string): Promise<MissionReport> {
    return new Promise<MissionReport>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${reportId}`).then(response => {
        resolve(response.data as MissionReport);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.message);
      })
    })
  }

  loadPrefilledReport(externalId: string, userid: string): Promise<MissionReport> {
    return new Promise<MissionReport>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${userid}/prefilled`).then(response => {
        resolve(response.data as MissionReport);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.message);
      })
    })
  }

  createMissionReport(externalId: string, missionReport: MissionReport, userId: string): Promise<MissionReport> {
    let userIdUrl = userId ? `?userId=${userId}` : '';
    return new Promise<MissionReport>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}${userIdUrl}`, missionReport).then((response) => {
        resolve(response.data as MissionReport);
      }, error => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.message);
      })
    })
  }

  updateMissionReport(externalId: string, reportId: string, report: MissionReport): Promise<MissionReport> {
    if (!externalId) {
      return Promise.reject();
    }
    return new Promise<MissionReport>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${reportId}`, report).then((report) => {
        resolve(report.data as MissionReport);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error.data.status);
      });
    });
  }


  publishMissionReport(externalId: string, reportId: string): Promise<MissionReport> {
    if (!externalId) {
      return Promise.reject();
    }
    return new Promise<MissionReport>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${reportId}/state`, {
        state: EMissionReportState.PUBLISHED
      } as UpdateMissionStateRequest).then((report) => {
        resolve(report.data as MissionReport);
      }, (error) => {
        this.errorService.notifyWithText(error.data.message);
        reject(error);
      });
    });
  }

  createAndSendMissionConfirmationForSinglePerson(externalId: string, reportId: string, personId: string) {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${reportId}/missionConfirm/${personId}`, undefined)
        .then(response => resolve()).catch(error => {
          this.errorService.notifyWithText(error.data.message);
          reject();
        }
      )
    })
  }

  createAndSendMissionConfirmation(externalId: string, reportId: string) {
    return new Promise<GeneralResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/missionReports/${encodeURIComponent(externalId)}/${reportId}/missionConfirm`, undefined)
        .then(response => resolve(response.data as GeneralResponse)).catch(error => {
        this.errorService.notifyWithText(error.data.message);
        reject();
      });
    })
  }

  getLocationMapGeoJSONforEmergency(emergency: Emergency): Promise<GeoJsonObject[]> {
    return new Promise<GeoJsonObject[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/lagekarte/geo/${encodeURIComponent(emergency.mapId)}?forceReload=true`)
        .then(res => resolve(res.data as GeoJsonObject[]))
        .catch(err => reject(err));
    });
  }

  saveMissionReport(emergency: Emergency): Promise<EmergencyReport> {
    return new Promise<EmergencyReport>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/emergency/${encodeURIComponent(emergency.externalId)}`, emergency.report)
        .then(res => resolve(res.data as EmergencyReport))
        .catch(err => reject(err));
    })
  }

  deleteEmergency(emergency: EmergencyResponse): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/emergency/delete/${encodeURIComponent(emergency.externalId)}/${encodeURIComponent(emergency.parentUserId)}`)
        .then(res => resolve())
        .catch(err => reject(err));
    });
  }

  closeMission(externalId: string) {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/emergency/${encodeURIComponent(externalId)}/close/mission`, null)
        .then(_ => resolve())
        .catch(err => reject(err));
    });
  }

  closeMissionAllowed(externalId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/emergency/${encodeURIComponent(externalId)}/close/mission/allowed`)
        .then(response => resolve(response.data as boolean))
        .catch(err => reject(err));
    });
  }


  //#####
  //EXTERNAL EXAMINATION
  //#####

  loadExaminationObjectById(id: string): Promise<ExaminationObject> {
    return new Promise<ExaminationObject>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/examination/${id}`)
        .then(res => resolve(res.data as ExaminationObject))
        .catch(err => reject(err));
    })
  }

  editObjectExamination(id: string, editRequest: ExaminationObjectEditRequest): Promise<ExaminationObject> {
    return new Promise<ExaminationObject>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/examination/${id}`, editRequest)
        .then(res => resolve(res.data as ExaminationObject))
        .catch(err => reject(err));
    })
  }

  resetExaminationForAlarmObject(id: string): Promise<GeneralResponse> {
    return new Promise<GeneralResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/examination/reset/${id}`)
        .then(result => resolve(result.data as GeneralResponse))
        .catch(err => reject(err));
    })
  }

  loadPipelineProtocolEntries(unitId: string, page: number, limit: number): Promise<Slice<PipelineProtocolEntry>> {
    return new Promise<Slice<PipelineProtocolEntry>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/pipeline/protocol/${unitId}?page=${page}&limit=${limit}`)
        .then(res => resolve(res.data as Slice<PipelineProtocolEntry>))
        .catch(err => reject(err));
    })
  }

  getForcePipelineProtocolForUser(userId: string): Promise<BooleanResponse> {
    return new Promise<BooleanResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/pipeline/protocol/${userId}`)
        .then(res => resolve(res.data as BooleanResponse))
        .catch(err => reject(err));
    })
  }

  setForcePipelineProtocolForUser(userId: string, toggle: BooleanResponse): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/pipeline/protocol/${userId}`, toggle)
        .then(_ => resolve())
        .catch(err => reject(err));
    })
  }

  stopStartTracking(vehicleGeoTrackingStateResponse: VehicleGeoTrackingStateResponse): Promise<VehicleGeoTrackingStateResponse> {
    return new Promise<VehicleGeoTrackingStateResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/geotracking/stopStart`, vehicleGeoTrackingStateResponse).then(result => {
        resolve(result.data as VehicleGeoTrackingStateResponse);
      }, error => {
        this.errorService.notifyWithText(error.data.message);
        reject(error);
      })
    })
  }

  getVehicleGeoTrackingState(): Promise<VehicleGeoTrackingStateResponse> {
    return new Promise<VehicleGeoTrackingStateResponse>(((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/geotracking/state`).then(result => {
        resolve(result.data as VehicleGeoTrackingStateResponse);
      }, error => {
        this.errorService.notifyWithText(error.data.message);
        reject(error);
      })
    }))
  }

  getInputTrackingToggles(): Promise<InputTrackingToggle[]> {
    return new Promise<InputTrackingToggle[]>(((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/geotracking/input`).then(result => {
        resolve(result.data as InputTrackingToggle[]);
      })
        .catch(err => {
          this.errorService.notifyWithText(err.data.message);
          reject(err);
        })
    }))
  }

  setInputTrackingToggles(inputClassSimpleName: string, trackingActive: boolean): Promise<InputTrackingToggle> {
    return new Promise<InputTrackingToggle>(((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/geotracking/input`, {
        inputClassSimpleName,
        trackingActive
      }).then(result => {
        resolve(result.data as InputTrackingToggle);
      })
        .catch(err => {
          this.errorService.notifyWithText(err.data.message);
          reject(err);
        })
    }))
  }

  getAmobileTrackingToggle(): Promise<boolean> {
    return new Promise<boolean>(((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/geotracking/amobile`).then(result => {
        resolve(result.data as boolean);
      })
        .catch(err => {
          this.errorService.notifyWithText(err.data.message);
          reject(err);
        })
    }))
  }

  setAmobileTrackingToggle(active: boolean): Promise<boolean> {
    return new Promise<boolean>(((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/geotracking/amobile`, active).then(result => {
        resolve(result.data as boolean);
      })
        .catch(err => {
          this.errorService.notifyWithText(err.data.message);
          reject(err);
        })
    }))
  }

  loadVehicleLocation(vehicleId: string): Promise<VehicleLocationResponse> {
    return new Promise<VehicleLocationResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${vehicleId}/location`)
        .then(res => resolve(res.data as VehicleLocationResponse))
        .catch(err => reject(err));
    });
  }

  loadVehicleLocationsForUser(): Promise<VehicleLocationResponse[]> {
    return new Promise<VehicleLocationResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/location`)
        .then(res => resolve(res.data as VehicleLocationResponse[]))
        .catch(err => reject(err));
    });
  }

  loadVehicleLocationHistory(vehicleId: string, startTime: Date, endTime: Date): Promise<VehicleLocationHistoryResponse> {
    return new Promise<VehicleLocationHistoryResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${vehicleId}/location/history?from=${startTime.valueOf()}&to=${endTime.valueOf()}`)
        .then(res => resolve(res.data as VehicleLocationHistoryResponse))
        .catch(err => reject(err));
    });
  }

  /**
   * Load Sms Eagle Settings Status
   * @returns
   */
  loadSmsEagleSettingsStatus(): Promise<ConfigStatusResponse> {
    return new Promise<ConfigStatusResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/settings/status`).then((res) => {
        resolve(res.data as ConfigStatusResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Load Sms Eagle Settings
   * @returns
   */
  loadSmsEagleSettings(): Promise<SmsEagleSettings> {
    return new Promise<SmsEagleSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/settings`).then((res) => {
        resolve(res.data as SmsEagleSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Save Sms Eagle Settings
   * @returns
   */
  saveSmsEagleSettings(settings: SmsEagleSettings): Promise<SmsEagleSettings> {
    return new Promise<SmsEagleSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/SmsEagle/settings`, settings).then((res) => {
        resolve(this.loadSmsEagleSettings());
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  loadRestProtocolSettings(): Promise<RestProtocolSettings> {
    return new Promise<RestProtocolSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/restProtocols/settings`).then((res) => {
        resolve(res.data as RestProtocolSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  saveRestProtocolSettings(settings: RestProtocolSettings): Promise<RestProtocolSettings> {
    return new Promise<RestProtocolSettings>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/restProtocols/settings`, settings).then((res) => {
        resolve(res.data as RestProtocolSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  loadSSOSettings(): Promise<SSOSettings> {
    return new Promise<SSOSettings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sso/settings`).then((res) => {
        resolve(res.data as SSOSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  saveSSOSettings(ssoSettings: SSOSettings) {
    return new Promise<SSOSettings>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/sso/settings`, ssoSettings).then((res) => {
        resolve(res.data as SSOSettings);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  isSsoOn() {
    return new Promise<SsoOnResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sso/isOn`).then((res) => {
        resolve((res.data as SsoOnResponse));
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  isSsoBlocked() {
    return new Promise<SsoBlockedResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sso/isBlocked`).then((res) => {
        resolve((res.data as SsoBlockedResponse));
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }
  ssoUnblock(){
    return new Promise<SsoBlockedResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sso/unblock`).then((res) => {
        resolve((res.data as SsoBlockedResponse));
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  getSsoLink(keepLoggedIn: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sso/authorizationLink?keepLoggedIn=${keepLoggedIn}`).then((res) => {
        resolve((res.data as GeneralResponse).message);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  loginAzure(code: string, keepLoggedIn: boolean): Promise<AccountResponse> {
    return new Promise<AccountResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/login/azure`, code).then((response) => {
        let loginResult = response.data as AccountResponse;

        if (loginResult.state === LoginTokenState.OK) {
          this.token = loginResult.token;
          this.$http.defaults.headers.common.Authorization = 'JWT ' + this.token;
          this.$log.debug('Successfully logged in: ' + this.token);

          if (keepLoggedIn) {
            this.localStorageService.set('token', this.token);
            this.localStorageService.set('loggedInFromAdmin', false);
          }
        }

        resolve(loginResult);
      }, (err) => {
        this.$log.error('Error occured during login with azure process:', err);
        reject(err);
      });
    });
  }

  /**
   * check SMSEagle Allowed Und Configured
   * @returns
   */
  checkSMSEagleAllowedAndConfigured(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/settings/on`).then((res) => {
        resolve((res.data as ConfigStatusResponse).configurationSet);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.error);
        reject(errorResponse);
      });
    });
  }

  /**
   * Send Sms Eagle test message
   * @returns
   */
  checkSMSEagleConnection(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/message/test`).then((res) => {
        resolve(res.status == 200);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * load SMSEagle Queue Length
   * @returns
   */
  loadSMSEagleQueueLength(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/queueLength`).then((res) => {
        resolve((res.data as SmsEagleQueueLength).length);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  /**
   * load SMSEagle Queue Length
   * @returns
   */
  loadSMSEagleSignal(): Promise<SmsEagleSignal> {
    return new Promise<SmsEagleSignal>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/SmsEagle/signal`).then((res) => {
        resolve(res.data as SmsEagleSignal);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadSinksForUser(page: number, pageSize: number): Promise<SinksResponsePaginated> {
    return new Promise<SinksResponsePaginated>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sinks?page=${page}&limit=${pageSize}`).then(response => {
        resolve(response.data as SinksResponsePaginated);
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      })

    })
  }

  loadSink(sinkId: string): Promise<SinkResponse> {
    return new Promise<SinkResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sinks/sink/${sinkId}`).then(response => {
        resolve(response.data as SinkResponse);
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      })
    })
  }

  getEmptySinkOfType(type: ESinkType): Promise<SinkResponse> {
    return new Promise<SinkResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/sinks/sink/create/${type}`).then((response) => {
        resolve(response.data as SinkResponse);
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      });
    });
  }

  saveSink(sinkToSave: SinkResponse): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/sinks/sink`, sinkToSave).then((response) => {
        resolve();
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      })
    })
  }

  deleteSink(sinkId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/sinks/sink/${sinkId}`).then(response => {
        resolve();
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      })
    })
  }

  changeSinksActiveState(sinkId: string): Promise<SinkCurrentStateResponse> {
    var request = {sinkId: sinkId} as SinkStateRequest;
    return new Promise<SinkCurrentStateResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/sinks/sink/${sinkId}/state`, request).then((response) => {
        resolve(response.data as SinkCurrentStateResponse);
      }, error => {
        reject(error.message);
        this.errorService.notifyWithText(error.message);
      })
    })
  }


  loadQueues(): Promise<Queue[]> {
    return new Promise<Queue[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues`).then((res) => {
        resolve(res.data as Queue[]);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadQueuesAsTable(displayRunning: boolean, displayAssigned: boolean, displayFinished: boolean): Promise<QueueTableResponse> {
    return new Promise<QueueTableResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/table?displayRunning=${displayRunning}&displayAssigned=${displayAssigned}&displayFinished=${displayFinished}`).then((res) => {
        resolve(res.data as QueueTableResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadQueue(queueId: string, filter: string): Promise<QueueResponse> {
    return new Promise<QueueResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/${queueId}?hiddenPrio=${filter}`).then((res) => {
        resolve(res.data as QueueResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadAllQueueEntries(): Promise<QueueEntryResponse[]> {
    return new Promise<QueueEntryResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries?withDone=true`).then((res) => {
        resolve(res.data as QueueEntryResponse[]);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadAllFinishedQueueEntries(): Promise<QueueEntryResponse[]> {
    return new Promise<QueueEntryResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/finished`).then((res) => {
        resolve(res.data as QueueEntryResponse[]);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  updateQueueEntryPriority(queueEntryId: string, request: UpdateQueuePriorityRequest): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/queues/entries/${queueEntryId}/priority`, request).then((res) => {
        resolve(res.data as QueueEntryResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  updateQueueEntryState(queueEntryId: string, request: UpdateQueueStateRequest): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/queues/entries/${queueEntryId}/state`, request).then((res) => {
        resolve(res.data as QueueEntryResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  moveQueueEntryState(queueEntryId: string, request: MoveToQueueRequest): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/queues/entries/${queueEntryId}/queue`, request).then((res) => {
        resolve(res.data as QueueEntryResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  changeQueueState(queueId: string, request: UpdateQueueActiveStateRequest): Promise<QueueResponse> {
    return new Promise<QueueResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/queues/${queueId}/state`, request).then((res) => {
        resolve(res.data as QueueResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  assignVehicleToQueue(queueEntryId: string, vehicleId: string): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/${queueEntryId}/vehicle/${encodeURI(vehicleId)}`).then((res) => {
        resolve(res.data as QueueEntryResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  removeVehicleFromQueue(queueEntryId: string, vehicleId: string): Promise<QueueEntryResponse> {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/queues/entries/${queueEntryId}/vehicle/${encodeURI(vehicleId)}`).then((res) => {
        resolve(res.data as QueueEntryResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  updateQueueEntryUnreadMessages(queueEntryId: string): Promise<QueueResponse> {
    return new Promise<QueueResponse>((resolve, reject) => {
      this.$http.patch(`${this.baseUrl}/queues/entries/${queueEntryId}/important`, false).then((res) => {
        resolve(res.data as QueueResponse);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadWeather(): Promise<DWDWeatherInformation> {
    return new Promise<DWDWeatherInformation>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/weather/`).then((res) => {
        resolve(res.data as DWDWeatherInformation);
      }).catch(err => reject(err));
    });
  }

  // Custom WMS Server

  loadWmsServerData(): Promise<WmsServerData> {
    return new Promise<WmsServerData>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/wms/server`)
        .then(res => resolve(res.data as WmsServerData))
        .catch(err => reject(err));
    });
  }

  saveWmsServerData(wmsServerData: WmsServerData): Promise<WmsServerData> {
    return new Promise<WmsServerData>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/wms/server`, wmsServerData)
        .then(res => resolve(res.data as WmsServerData))
        .catch(err => reject(err));
    });
  }

  loadAvailableWmsLayers(): Promise<WmsLayerData[]> {
    return new Promise<WmsLayerData[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/wms/layer`)
        .then(res => resolve(res.data as WmsLayerData[]))
        .catch(err => reject(err));
    });
  }

  updateWmsLayerData(layerName: string, updateRequest: WmsLayerDataUpdateRequest) {
    return new Promise<WmsLayerData>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/wms/layer/${layerName}`, updateRequest)
        .then(res => resolve(res.data as WmsLayerData))
        .catch(err => reject(err));
    });
  }

  sendTestEmail(from: string, to: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/mailsendtest?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`).then((res) => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data);
      });
    });
  }


  loadWeatherWarnings(): Promise<WeatherAndPegelWarnings> {
    return new Promise<WeatherAndPegelWarnings>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/meta/list/warnings/`).then((res) => {
        resolve(res.data as WeatherAndPegelWarnings);
      }, (errorResponse) => {
        reject(errorResponse);
      });
    });
  }

  loadIOprints(): Promise<IOPrintsInfoResponse[]> {
    return new Promise<IOPrintsInfoResponse[]>((resolve, reject) => {
      this.$http.get(this.baseUrl + '/ioprint/all').then((response) => {
        this.$log.debug(response.data);
        resolve(response.data as IOPrintsInfoResponse[]);
      }, (response) => {
        this.$log.error('Error getting IOprints');
        reject(response);
      });
    });
  }

  loadIOPrintConfigString(id: string): Promise<String> {
    return new Promise<String>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/ioprint/${id}/config`).then((res) => {
        resolve((res.data as GeneralResponse).message);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);

      });
    });
  }

  printTestPage(id: string) {
    return new Promise<String>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/ioprint/${id}/test`, undefined).then((res) => {
        resolve((res.data as GeneralResponse).message);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse.data.message);
      });
    });
  }

  updateIOprint(ioprint: string, request: IOprintUpdateRequest) {
    return new Promise<IOPrintsInfoResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/ioprint/${ioprint}/update`, request)
        .then(res => resolve(res.data as IOPrintsInfoResponse))
        .catch(err => reject(err));
    });
  }

  downloadEmergencyImages(mapId: string, forceReload: boolean) {
    return new Promise<EmergencyImageInfo[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/emergency/${encodeURIComponent(mapId)}/images?forceReload=${forceReload}`)
        .then(res => resolve(res.data as EmergencyImageInfo[]))
        .catch(err => reject(err));
    });
  }


  downloadEmergencyImage(fileId: string) {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/emergency/${encodeURIComponent(fileId)}/image`)
        .then(res => resolve((res.data as LagekarteImageData).imgData))
        .catch(err => reject(err));
    });
  }

  getObjectDocumentTagsAll() {
    return new Promise<ObjectDocumentTagsResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/objects/document/tag/all`)
        .then(result => {
          resolve(result.data as ObjectDocumentTagsResponse)
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  editDocumentTag(tag: DocumentTag) {
    return new Promise<DocumentTag>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/objects/document/tag/${tag.id}`, tag)
        .then(result => {
          resolve(result.data as DocumentTag)
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  addDocumentTag(tag: DocumentTag) {
    return new Promise<DocumentTag>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/objects/document/tag`, tag)
        .then(result => {
          resolve(result.data as DocumentTag)
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  deleteDocumentTag(tagId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/objects/document/tag/${tagId}`).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
      })
    })
  }

  getObjectDocuments(page: number, limit: number, tagsFilter: string, filter: string) {
    return new Promise<ObjectDocumentsPageResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/objects/document/page?page=${page}&limit=${limit}&tagsFilter=${tagsFilter}&filter=${filter}`)
        .then(result => {
          resolve(result.data as ObjectDocumentsPageResponse)
        })
        .catch(error => {
          reject(error);
        });
    });

  }

  editDocumentObject(document: ObjectDocument) {
    return new Promise<ObjectDocument>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/objects/document/${document.id}/data?overwrite=true`, document).then((res) => {
        resolve(res.data as ObjectDocument);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }


  addDocumentObject(document: ObjectDocument, file: any) {
    return new Promise<ObjectDocument>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/objects/document?file=${encodeURIComponent(file)}`, document).then((res) => {
        resolve(res.data as ObjectDocument);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  deleteObjectDocuments(id: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/objects/document/${id}`).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
      });
    });
  }

  getChatroomSlice(page: number, size: number): Promise<Slice<SimpleChatroomResponse[]>> {
    return new Promise<Slice<SimpleChatroomResponse[]>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/chat?page=${page}&size=${size}`)
        .then(res => resolve(res.data as Slice<SimpleChatroomResponse[]>))
        .catch(err => reject(err));
    });
  }

  getChatroomAdminUser(): Promise<ChatroomAdminUserResponse> {
    return new Promise<ChatroomAdminUserResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/chat/adminUser`)
        .then(res => resolve(res.data as ChatroomAdminUserResponse))
        .catch(err => reject(err));
    });
  }


  getSingleChatroom(alias: string): Promise<ChatroomResponse> {
    return new Promise<ChatroomResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/chat/${alias}`)
        .then(res => resolve(res.data as ChatroomResponse))
        .catch(err => reject(err));
    });
  }

  loadSirenLocations(): Promise<VehicleSirenResponse[]> {
    return new Promise<VehicleSirenResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/sirens/location`)
        .then(res => resolve(res.data as VehicleSirenResponse[]))
        .catch(err => reject(err));
    })
  }

  syncIvenaObjects(): Promise<{ newHospitals: number, existingHospitals: number }> {
    return new Promise<{ newHospitals: number, existingHospitals: number }>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/objects/ivena`, undefined)
        .then(res => resolve(res.data as { newHospitals: number, existingHospitals: number }))
        .catch(err => reject(err));
    })
  }

  movePersonToUser(personId: string, newUserId: string) {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/admin/addressbook/${personId}/move`, {userId: newUserId})
        .then(res => resolve())
        .catch(err => reject(err));
    })
  }

  getCurrentWorkload(): Promise<WorkloadResponse> {
    return new Promise<WorkloadResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/workload`)
        .then(res => resolve(res.data as WorkloadResponse))
        .catch(err => reject(err));
    })
  }

  /**
   * Add a new Device to a given Vehicle
   * @param vehicleId The vehicle ID
   * @returns
   */
  addDevice(vehicleId: string): Promise<DeviceResponse> {
    return new Promise<DeviceResponse>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/vehicles/${vehicleId}/devices`, {
        deviceType: EDeviceType.PRESSLUFTATMER
      } as CreateDeviceRequest).then((res) => {
        resolve(res.data as DeviceResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Get all devices for a given vehicle
   * @param vehicleId
   * @returns
   */
  getDevicesForVehicle(vehicleId: string, currentPage: number, pageSize: number): Promise<Page<DeviceResponse>> {
    return new Promise<Page<DeviceResponse>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${vehicleId}/devices?page=${currentPage}&limit=${pageSize}`)
        .then(res => resolve(res.data as Page<DeviceResponse>))
    });
  }

  /**
   * Get history devices for a given vehicle
   * @param vehicleId
   * @returns
   */
  getDevicesHistoryForVehicle(vehicleId: string, currentPage: number, pageSize: number): Promise<Slice<DeviceStateChangeResponse>> {
    return new Promise<Slice<DeviceStateChangeResponse>>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${vehicleId}/devices/history?page=${currentPage}&limit=${pageSize}`)
        .then(res => resolve(res.data as Slice<DeviceStateChangeResponse>))
    });
  }

  /**
   * Update existing Device
   * @returns
   */
  saveDevice(device: DeviceResponse): Promise<DeviceResponse> {
    return new Promise<DeviceResponse>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/vehicles/${device.vehicleId}/devices/${device.id}`, device).then((res) => {
        resolve(res.data as DeviceResponse);
      }, (errorResponse) => {
        this.errorService.notifyWithText(errorResponse.data.message);
        reject(errorResponse);
      });
    });
  }

  /**
   * Delete an existing Device, given his ID and the vehicles ID
   */
  deleteDevice(vehicleId: string, deviceId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.delete(`${this.baseUrl}/vehicles/${vehicleId}/devices/${deviceId}`).then(() => {
        resolve(true);
      }, (errorResponse) => {
        reject(errorResponse.data.message);
      });
    });
  }

  getAllVehiclesWithDevices(search: string, page: number, notUsableOnly: boolean): Promise<Slice<VehicleDeviceResponse>> {
    return new Promise<Slice<VehicleDeviceResponse>>((resolve, reject) => {
      const limit = 10;
      this.$http.get(`${this.baseUrl}/vehicles/devices?page=${page}&limit=${limit}&search=${search}&notUsableOnly=${notUsableOnly}`)
        .then(res => {
          resolve(res.data as Slice<VehicleDeviceResponse>);
        })
        .catch(errorResponse => {
          this.errorService.notifyWithText(errorResponse.data.message);
          reject(errorResponse);
        })
    })
  }

  changeDeviceState(vehicleId: string, deviceIds: string[], newState: EDeviceState) {
    return new Promise<DeviceResponse[]>((resolve, reject) => {
      const body = {deviceIds, newState};
      this.$http.put(`${this.baseUrl}/vehicles/${vehicleId}/devices/state`, body)
        .then(res => resolve(res.data as DeviceResponse[]))
        .catch(err => {
          this.errorService.notifyWithText(err.data.message)
          reject(err.data.message);
        });
    })
  }

  public loadIvenaMappings(): Promise<IvenaMapping[]> {
    return new Promise<IvenaMapping[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/objects/ivena/mappings`)
        .then(res => resolve(res.data as IvenaMapping[]))
        .catch(err => reject(err));
    });
  }

  public saveIvenaMappings(mappings: IvenaMapping[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/objects/ivena/mappings`, mappings)
        .then(res => resolve())
        .catch(err => reject(err));
    });
  }

  public getStatusDefinitions(): Promise<StatusDefinition[]> {
    return new Promise<StatusDefinition[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/settings/status`)
        .then(res => resolve(res.data as StatusDefinition[]))
        .catch(err => reject(err));
    });
  }

  public getStatusDefinitionExportUrl(): string {
    return `${this.baseUrl}/admin/settings/status/csv?Authorization=${this.$http.defaults.headers.common.Authorization}`;
  }

  getLogPath(dir, log) {
    return this.baseUrl + '/logs/' + log.fileName + '?currentDir=' + encodeURIComponent(dir) + '&Authorization=' + this.getAuthHeader();
  }

  getLogPathDownload(dir, log) {
    return this.baseUrl + '/logs/' + log.fileName + '/download?currentDir=' + encodeURIComponent(dir) + '&Authorization=' + this.getAuthHeader();
  }

  getLogZipPath(dateFilter: number) {
    return this.baseUrl + '/logs/zip?dateFilter=' + dateFilter + '&Authorization=' + this.getAuthHeader();
  }

  getConnections(): Promise<MqttStatus[]> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/connections/mqtt/status`)
        .then(res => resolve(res.data as MqttStatus[]))
        .catch(err => reject(err));
    });
  }

  getHttpMetrics(): Promise<HttpMetrics[]> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/connections/http/status`)
        .then(res => resolve(res.data as HttpMetrics[]))
        .catch(err => reject(err));
    });
  }

  getStaticConnections(): Promise<ConnectionResponse[]> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/connections/static`)
        .then(res => resolve(res.data as ConnectionResponse[]))
        .catch(err => reject(err));
    });
  }

  getIngresses(): Promise<BackendFallbackInfo> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/connections/ingresses`)
        .then(res => resolve(res.data as BackendFallbackInfo))
        .catch(err => reject(err));
    });
  }

  // we need to proxy this because of CORS
  canReachIngress(host: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/connections/ingress/${host}`)
        .then(res => resolve(res.data as boolean))
        .catch(err => reject(err));
    });
  }

  loadAnnouncementForWidget(): Promise<AnnouncementDisplay> {
    return new Promise<AnnouncementDisplay>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/announcements/widget`).then((response) => {
        resolve(response.data as AnnouncementDisplay);
      }, (error) => {
        reject(error.data.message);
      });
    });
  }


  loadCustomerNotifications(): Promise<CustomerNotificationResponse[]> {
    return new Promise<CustomerNotificationResponse[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/admin/ccn`).then((response) => {
        resolve(response.data as CustomerNotificationResponse[]);
      }, (error) => {
        reject(error.data.message);
      });
    });
  }

  getKbaHistory(page: number, limit: number): Promise<KbaResponse> {
    return new Promise<KbaResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/rescue-card/requests?page=${page}&limit=${limit}`).then((response) => {
        resolve(response.data as KbaResponse);
      }, (error) => {
        reject(error.data.message);
      });
    });
  }

  getAllPendingKbaRequests(): Promise<KbaRequest[]> {
    return new Promise<KbaRequest[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/rescue-card/requests/pending`).then((response) => {
        resolve(response.data as KbaRequest[]);
      }, (error) => {
        reject(error.data.message);
      });
    });
  }


  approvePendingKbaRequest(requestId: string, request: ApproveKbaRequest): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/rescue-card/${requestId}/approve`, request).then((response) => {
        resolve(true);
      }, (error) => {
        reject(error.data.message);
      });
    });
  }

  searchMap(searchString: string) {
    return new Promise<MapSearchResults>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/global/map/search?sFilter=${encodeURIComponent(searchString)}`).then(response => {
        resolve(response.data as MapSearchResults);
      }).catch(error => {
        reject(error.data.message);
      })
    })
  }

  getDiaryEntriesForLocationMap(queueEntryId: string, locationMapId: string) {
    return new Promise<DiaryEntry[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/${queueEntryId}/locationMap/${locationMapId}/feedback`).then(response => {
        resolve(response.data as DiaryEntry[]);
      }).catch(error => {
        reject(error.data);
      });
    });
  }

  createDairyEntryForLagekarte(queueEntryId: string, locationMapId: string, diary: DiaryPayload) {
    return new Promise<any>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/queues/entries/${queueEntryId}/locationMap/${locationMapId}/feedback`, diary).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.data);
      });
    });
  }

  getTaskEntriesForLocationMap(queueEntryId: string, locationMapId: string) {
    return new Promise<LocationMapTask[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/${queueEntryId}/locationMap/${locationMapId}/task`).then(response => {
        resolve(response.data as LocationMapTask[]);
      }).catch(error => {
        reject(error.data);
      });
    });
  }

  createTaskEntryForLagekarte(diaryEntryId: string, locationMapId: string, queueEntryId: string, task: LocationMapTaskPayload) {
    return new Promise<any>((resolve, reject) => {
      this.$http.post(`${this.baseUrl}/queues/entries/${queueEntryId}/locationMap/${locationMapId}/${diaryEntryId}/task`, task).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.data);
      });
    });
  }

  getQueueEntryWithNewestAlarmData(queueEntryId: string) {
    return new Promise<QueueEntryResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/${queueEntryId}`).then(response => {
        resolve(response.data as QueueEntryResponse);
      }).catch(error => {
        reject(error.data.message);
      });
    });
  }

  getAllTasksForQueues(filter: string) {
    return new Promise<LocationMapTask[]>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/tasks?filter=${filter}`).then(response => {
        resolve(response.data as LocationMapTask[]);
      }).catch(error => {
        reject(error.data.message);
      });
    });
  }

  updateTask(taskId: string, locationMapId: string, state: ELocationMapTaskStatus) {
    return new Promise<LocationMapTask>((resolve, reject) => {
      this.$http.put(`${this.baseUrl}/queues/tasks/${locationMapId}/${taskId}`, state).then(response => {
        resolve(response.data as LocationMapTask);
      }).catch(error => {
        reject(error.data.message);
      });
    });
  }

  getImageByIdForLagekarte(locationMapId: string, fileId: string) {
    return new Promise<EmergencyImageInfo>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/${locationMapId}/images/${encodeURIComponent(fileId)}`)
        .then(res => {
          resolve(res.data as EmergencyImageInfo);
        }).catch(err => reject(err));
    });
  }

  getThumbnailToken(locationMapId: string, queueEntryId: string) {
    return new Promise<string>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/queues/entries/${queueEntryId}/locationMap/${locationMapId}/imageToken`).then(response => {
        resolve(response.data as string);
      }).catch(error => {
        reject(error.data);
      });
    });
  }

  doLipPolling(vehicleId: string): Promise<VehicleLocationResponse> {
    return new Promise<VehicleLocationResponse>((resolve, reject) => {
      this.$http.get(`${this.baseUrl}/vehicles/${vehicleId}/location/poll`).then(response => {
        resolve(response.data as VehicleLocationResponse);
      }).catch(error => {
        reject(error.data);
      });
    });
  }
}
